import { pull } from 'lodash-es';

export default angular
  .module('service.compare', [])

  /* @ngInject */
  .factory('CompareService', function ($rootScope, $http, $q, $cookies, $location, DateService) {
    if (typeof $location.search().c !== 'undefined') {
      const arr = $location.search().c.split(',');
      arr.forEach((item, i) => {
        arr[i] = parseInt(item);
      });
      $cookies.putObject('comparator', arr, {
        path: '/',
        expires: DateService.tomorrow()
      });
    }

    let products = $cookies.getObject('comparator') || [];
    let open = '0';
    let maxItems;

    let promises = {};

    function updateCookie() {
      let search = $location.search();
      if (!products.length) {
        search = angular.extend(search, { c: [], open: [] });
        $location.update({ search });
        $cookies.remove('comparator');
        $rootScope.$broadcast('comparatorEmpty');
        return;
      }
      search = angular.extend(search, { c: products.join(','), open: open === '1' ? open : [] });
      $location.update({ search });
      $cookies.putObject('comparator', products, {
        path: '/',
        expires: DateService.tomorrow()
      });
    }

    function cancelPromises() {
      Object.keys(promises).forEach(key => {
        const item = promises[key];
        item.resolve('aborted');
      });
      promises = {};
    }

    return {
      getNbProducts: () => products.length,
      get: () => products,
      add: id => {
        cancelPromises();
        products.push(id);
        updateCookie();
      },
      remove: id => {
        cancelPromises();
        pull(products, id);
        updateCookie();
      },
      toggle: value => {
        open = value ? '1' : '0';
        updateCookie();
      },
      empty: () => {
        products = [];
        updateCookie();
      },
      getProduct: id => {
        const canceller = $q.defer();
        promises[id] = canceller;
        return $http({
          method: 'GET',
          url: `/o-p-${id}`,
          cache: true,
          headers: {
            'Content-type': 'application/json',
            'X-Requested-With': 'jsonHttpRequest'
          },
          timeout: canceller.promise
        })
          .then(response => {
            delete promises[id];
            return response.data;
          })
          .catch(error => {
            throw error;
          });
      },
      maxItems
    };
  });
