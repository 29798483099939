export default angular
  .module('service.authentication', [])

  /* @ngInject */
  .factory('LogService', function ($templateCache, $cacheFactory, AccountService, HttpService, Routes) {
    let redirect, email, url;

    url = Routes.filter(r => r.Name === 'Login')[0];
    url = url[_LNG_] ? url[_LNG_].URL : url.en.URL;

    return {
      login: data =>
        HttpService.post({
          url: `/${url}`,
          data
        })
          .then(response => {
            $cacheFactory.get('$http').removeAll();
            return response;
          })
          .catch(error => {
            console.error(error);
          }),
      logout: () =>
        HttpService.post({
          url: '/Logout',
          data: {}
        })
          .then(response => {
            $cacheFactory.get('$http').removeAll();
            $templateCache.remove(AccountService.getLinks().AccountUpdate);
            $templateCache.remove(AccountService.getLinks().AccountHome);
            return response;
          })
          .catch(error => {
            console.error(error);
          }),
      redirect,
      email
    };
  });
