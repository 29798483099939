export default angular
  .module('directive.mini-cart', [])

  .directive('miniCart', function () {
    return {
      restrict: 'E',
      /* @ngInject */
      controller: function ($scope, $element, $filter, AppService, RoutesService, ModalService, WebCartService) {
        const ctrl = this;

        // Translate
        const tplTranslate = {};
        $element
          .children('.translate')
          .children()
          .each(function () {
            const $this = $(this);
            tplTranslate[$this.attr('key')] = $this.html();
          });

        getCart();

        ctrl.emptyCart = () => {
          ModalService.show('/Template/Cart/ModalClearCartConfirm', {}, null, 'clearCartConfirmModalCtrl');
        };

        ctrl.remove = line => {
          const product = {
            idProduct: line.Product.IDProduct,
            quantity: line.Quantity,
            Price: {
              TTCPrice: line.TTCAmount
            }
          };
          ModalService.show(
            '/Template/Cart/ModalDeleteConfirm',
            {
              action: () => {
                WebCartService.deleteLine(line.IDLine, product, line.Product.Designation, line.Product.IDPicture, line.Product.DistrimageSrc, tplTranslate.titleDeleteOk);
              }
            },
            null,
            'deleteConfirmModalCtrl'
          );
        };

        $scope.$on('cartUpdate', () => {
          getCart();
        });

        $scope.$on('cartUpdateWithRefresh', () => {
          getCart(true);
        });

        async function getCart(withRefresh) {
          ctrl.cartIsActive = true;
          const params = await AppService.getParams(withRefresh);
          if (params.Agent && params.Mobility.Params.UseSaleAsCart && !params.Mobility.Profile.AllModules.find(p => p.ModuleKey === 'sale')?.IsActive) {
            ctrl.cartIsActive = false;
          }
          ctrl.hasCart = params.HasCart && params.Cart && params.Cart.Products && params.Cart.Products.length;
          ctrl.cartLink = !ctrl.hasCart ? '' : `/${RoutesService.getUrlByName('Cart')}`;
          ctrl.showTTCPrice = params.Visitor.CardType.ShowTTCPrice;
          ctrl.count = 0;
          if (ctrl.hasCart) {
            ctrl.data = params.Cart;
            params.Cart.Products.forEach(line => {
              if (params.Cart.TradeActions.find(tradeAction => tradeAction.IDProduct === line.IDProduct)) {
                line.isGift = true;
              }
            });
            ctrl.cartProducts = $filter('orderBy')(
              params.Cart.Products.filter(line => !line.isGift),
              'LineNumber'
            );
            ctrl.count = ctrl.cartProducts.reduce((result, line) => result + line.Quantity, 0);
          }
          !$scope.$$phase && $scope.$digest();
        }
      },
      controllerAs: 'miniCartCtrl'
    };
  })

  /* @ngInject */
  .controller('deleteConfirmModalCtrl', function (data, device, options, ModalService) {
    const ctrl = this;

    ctrl.submit = () => {
      ModalService.close();
      data.action();
    };
    ctrl.cancel = () => {
      ModalService.close();
      if (data.cancel) data.cancel();
    };
  })

  /* @ngInject */
  .controller('clearCartConfirmModalCtrl', function (data, $rootScope, HttpService, AppService, ModalService, toastr, NetwaveService, GA4Service) {
    const ctrl = this;

    ctrl.submit = async () => {
      ModalService.close();
      $rootScope.$broadcast('showPageLoader', true);

      try {
        const response = await HttpService.post({
          url: 'Cart/ClearCart',
          data: {}
        });
        const { messages, errors } = await AppService.getTranslate();
        if (response.status === 'OK') {
          await NetwaveService.emptyCart(response.removedProducts);
          AppService.updateParams(response.results);
          toastr.success('', messages.ClearCartOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
          if (data.action) data.action();
          const currency = response.results && response.results.Currency && response.results.Currency.ISOCode ? response.results.Currency.ISOCode : null;
          GA4Service.removeFromCart([], response.removedProducts, currency, response.results.Localization.TradeName);
        } else {
          toastr.warning(errors.TryLater, errors.ErrorHasOccurred, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
      $rootScope.$broadcast('showPageLoader', false);
      !$rootScope.$$phase && $rootScope.$digest();
    };
  });
