import hoverintent from 'hoverintent';

export default angular
  .module('directive.nav', [])

  .directive('rbMainNav', function () {
    return {
      restrict: 'A',
      bindToController: true,
      controllerAs: 'mainNavCtrl',
      /* @ngInject */
      controller: function ($rootScope, $scope, $element, $timeout, WindowEventsService) {
        const ctrl = this;

        ctrl.open = false;
        ctrl.children = [];

        ctrl.nicescrollOptions = {
          horizrailenabled: false,
          autohidemode: false,
          cursorwidth: '6px',
          cursorcolor: '#999',
          cursorborder: '1px solid #999',
          cursorborderradius: '4px',
          railpadding: { top: 5, right: 0, left: 0, bottom: 5 },
          zindex: 1000000
        };

        ctrl.toggle = () => {
          ctrl.open = !ctrl.open;
          let i;
          const len = ctrl.children.length;
          for (i = 0; i < len; i++) {
            ctrl.children[i].open = false;
          }
        };

        const $nav = $element.children('.nav');
        let size = parseFloat($nav.css('font-size'));
        let padding = parseFloat($nav.children().eq(0).children('span').css('padding-left'));
        const origSize = size;
        ctrl.style = '';
        ctrl.menuStyle = '';
        resizeMenus();

        // Events

        WindowEventsService.listen(true, 'resize', resize, 500);

        $scope.$on('$destroy', () => {
          WindowEventsService.listen(false, 'resize', resize);
        });

        $rootScope.$on('toggleNav', () => {
          ctrl.toggle();
        });

        // Functions

        function resize() {
          ctrl.style = '';
          ctrl.menuStyle = '';
          $timeout(() => {
            size = parseFloat($nav.css('font-size'));
            padding = parseFloat($nav.children().eq(0).children('span').css('padding-left'));
            ctrl.active = false;
            size = origSize;
            $timeout(resizeMenus);
          });
        }

        function resizeMenus() {
          if ($scope.device.desktop) {
            if ($nav.width() > $element.width()) {
              if (padding > 5) {
                padding -= 1;
                ctrl.menuStyle = { 'padding-left': `${padding}px`, 'padding-right': `${padding}px` };
              } else {
                size -= 0.5;
                ctrl.style = { 'font-size': `${size}px` };
              }
              $timeout(resizeMenus);
            } else {
              ctrl.active = true;
            }
          } else {
            ctrl.style = '';
            ctrl.menuStyle = '';
            ctrl.active = true;
          }
        }
      }
    };
  })

  /* @ngInject */
  .directive('rbNav', function ($document, $animate) {
    return {
      restrict: 'A',
      scope: {
        psOpen: '=?'
      },
      link: (scope, element) => {
        element.addClass('ng-pageslide');

        const body = document.body;
        const $body = $(body);
        const $navMenu = $('#navMenu');
        const $header = $('#header');
        const $hamburger = $('#hamburger');
        const slider = element[0];

        function onBodyClick(e) {
          if (scope.psOpen && !slider.contains(e.target)) {
            scope.$apply(() => {
              scope.psOpen = false;
            });
          }
        }

        $body.addClass('offcanvas');

        if (slider.children.length === 0) {
          throw new Error('You need to have content inside the <pageslide>');
        }

        // Closed
        function psClose() {
          if ($body.hasClass('offcanvas-left')) {
            $('.move-out').removeClass('move-out'); // TODO
            $animate.removeClass(body, 'offcanvas-left').then(closeDone);
            $document.off('click', onBodyClick);
          }
        }
        function closeDone() {
          scope.psOpen = false;
        }
        // Open
        function psOpen() {
          if (!$body.hasClass('offcanvas-left')) {
            $navMenu.css({ top: $header.position().top + $hamburger.height() - $(window).scrollTop() });
            $animate.addClass(body, 'offcanvas-left').then(openDone);
            $document.on('click', onBodyClick);
          }
        }
        function openDone() {
          scope.psOpen = true;
        }

        // Watchers

        scope.$watch('psOpen', value => {
          if (!!value) {
            psOpen();
          } else {
            psClose();
          }
        });

        // Events

        scope.$on('$destroy', () => {
          if (slider.parentNode === body) {
            $document.off('click', onBodyClick);
          }
        });

        scope.$on('$locationChangeStart', () => {
          psClose();
        });
      }
    };
  })

  /* @ngInject */
  .directive('rbMenu', function ($animate, $injector, $location) {
    const $animateCss = $injector.has('$animateCss') ? $injector.get('$animateCss') : null;
    return {
      restrict: 'A',
      scope: true,
      link: (scope, element) => {
        scope.mainNavCtrl.children.push(scope);
        //----
        scope.open = false;
        scope.element = element;

        initMenu();
        initLevelsLinks();

        scope.expandMenu = () => {
          const $menu = element.addClass('in').children('.menu');
          element.children('a').addClass('in');

          $menu.addClass('collapsing').attr('aria-expanded', true).attr('aria-hidden', false);

          if (scope.$parent.device.isTouch) {
            $('#main-nav').addClass('in');
            $menu.after('<div class="backdrop"/>');
            element.children('.backdrop').on('click', function () {
              $(this).remove();
              scope.reduceMenu();
            });
          }

          function expandDone() {
            $menu.removeClass('collapsing').css({ height: 'auto' });
          }

          if ($animateCss) {
            $animateCss($menu, {
              addClass: 'in',
              easing: 'ease',
              to: { height: `${$menu[0].scrollHeight}px` }
            })
              .start()
              .finally(expandDone);
          } else {
            $animate
              .addClass($menu, 'in', {
                to: { height: `${$menu[0].scrollHeight}px` }
              })
              .then(expandDone);
          }

          setTimeout(() => {
            $(window).trigger('scroll');
          }, 350);
        };

        scope.reduceMenu = () => {
          const $menu = element.removeClass('in').children('.menu');
          if (!$menu.length) return;

          element.children('a').removeClass('in');
          element.children('.backdrop').remove();
          $('#main-nav').removeClass('in');

          $menu
            .css({ height: `${$menu[0].scrollHeight}px` })
            .addClass('collapsing')
            .attr('aria-expanded', false)
            .attr('aria-hidden', true);

          function collapseDone() {
            $menu.css({ height: '0' }).removeClass('collapsing');
          }

          if ($animateCss) {
            $animateCss($menu, {
              removeClass: 'in',
              to: { height: '0' }
            })
              .start()
              .finally(collapseDone);
          } else {
            $animate
              .removeClass($menu, 'in', {
                to: { height: '0' }
              })
              .then(collapseDone);
          }
        };

        scope.openMenu = event => {
          if (window.isBot || window.isCache) return;
          event.preventDefault();
          if (!scope.$parent.device.desktop) {
            scope.open = !scope.open;
            element.siblings().addClass('move-out').end().closest('.nav').scrollTop(0);
          } else {
            if (scope.$parent.device.isTouch && element.hasClass('level1')) {
              const $menu = element.children('.menu');
              if (!$menu.hasClass('collapsing') && !$menu.hasClass('in')) {
                const ctrl = scope.mainNavCtrl;
                let i;
                const len = ctrl.children.length;
                for (i = 0; i < len; i++) {
                  if (ctrl.children[i].element !== element && ctrl.children[i].element.children('.menu.in').length) {
                    ctrl.children[i].reduceMenu();
                  }
                }
                scope.expandMenu();
              }
              return;
            }
            $location.path(element.children('a').attr('href'));
          }
        };

        scope.backMenu = event => {
          event.preventDefault();
          scope.open = !scope.open;
          element.siblings().removeClass('move-out');
          $('#menu-list').removeClass('move-out');
        };

        scope.closeMenu = () => {
          scope.mainNavCtrl.open = false;
        };

        // Functions

        function initMenu() {
          if (scope.$parent.device.isTouch || !scope.$parent.device.desktop || !element.hasClass('level1')) return;

          hoverintent(
            element[0],
            () => {
              scope.expandMenu();
            },
            () => {
              scope.reduceMenu();
            }
          ).options({
            timeout: 200,
            interval: 100
          });
        }

        function initLevelsLinks() {
          element.find('.menu-list').on('click', 'a', event => {
            if (scope.device.desktop) {
              scope.reduceMenu();
            }
          });
        }
      }
    };
  });
