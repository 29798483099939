/**
 * @class specifics.Garden.gardenDirective
 * @memberOf specifics.Garden
 * @desc Controller du module potager pour le lazy loading du composant
 * @example
 * <div ng-controller="gardenController as ctrl">
 *  <!-- potager -->
 * </div>
 */

export default angular.module('directive.garden-configurator', []).directive('gardenConfigurator', function () {
  return {
    restrict: 'A',
    bindToController: {
      idCategory: '<idCategory'
    },
    controllerAs: 'gardenCtrl',
    /* @ngInject */
    controller: function ($injector, $scope, $element) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "garden" */ 'Modules/GardenConfigurator/import/garden.module.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('GardenConfigurator');
        service.initModule(ctrl, $scope, $element);
      };
    }
  };
});
