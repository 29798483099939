export default angular
  .module('directive.header', [])

  .directive('mainHeader', function () {
    return {
      restrict: 'A',
      link: function () {
        const $searchBlock = $('.o-header__search');
        const $searchInput = $('#searchZone');
        $('.js-searchButton').click(() => {
          $searchBlock.toggleClass('active no-active');
          if ($searchBlock.hasClass('active')) {
            setTimeout(() => {
              $searchInput.focus();
            }, 500);
          } else {
            $searchInput.blur().val('');
          }
        });
      }
    };
  });
