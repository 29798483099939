(function () {
  'use strict';

  angular
    .module('service.ga4', [])

    /* @ngInject */
    .factory('GA4Service', function (AppService) {

      const ga4Enable = function () {
        return AppService.getAllParams('GA4_Enable');
      };

      // Récupérer un item
      const getItem = function (product, categories, affiliation, coupon) {
        if (!product) return;
        let parentProduct = null;
        if (product.hasOwnProperty('Product') && product.Product) {
          parentProduct = product;
          product = product.Product;
        }
        const quantity = getQuantity(product, parentProduct);
        const item = {
          item_id: getReference(product, parentProduct),
          item_name: getDesignation(product, parentProduct),
          affiliation,
          coupon,
          discount: product.Price ? (Math.round((product.Price.TTCPrice - product.Price.TTCDiscountedPrice) * 100) / 100) : null,
          item_brand: getBrand(product),
          price: product.Price ? product.Price.TTCDiscountedPrice : null,
          quantity: quantity ? Math.abs(quantity) : null,
          item_category: categories && categories.length > 0 ? categories[categories.length - 1] : null,
          item_category2: categories && categories.length > 1 ? categories[categories.length - 2] : null,
          item_category3: categories && categories.length > 2 ? categories[categories.length - 3] : null,
          item_category4: categories && categories.length > 3 ? categories[categories.length - 4] : null,
          item_category5: categories && categories.length > 4 ? categories[categories.length - 5] : null,
          item_variant: product.hasOwnProperty('IDGroupingProduct') && product.IDGroupingProduct > 0 ? product.IDGroupingProduct : null
        };
        const properties = Object.entries(item);
        for (let i = 0; i < properties.length; i++) {
          if (typeof properties[i][1] === 'undefined' || properties[i][1] === null) {
            delete item[properties[i][0]];
          }
        }
        return item;
      };

      const getQuantity = function (product, parentProduct) {

        if (product && product.hasOwnProperty('Quantity')) {
          return product.Quantity;
        } else if (parentProduct && parentProduct.hasOwnProperty('Quantity')) {
          return parentProduct.Quantity;
        }
        return null;
      };

      const getDesignation = function (product, parentProduct) {
        if (product && product.hasOwnProperty('Designation')) {
          return product.Designation;
        } else if (product && product.hasOwnProperty('designation')) {
          return product.designation;
        } else if (parentProduct && parentProduct.hasOwnProperty('Designation')) {
          return parentProduct.Designation;
        }
        return null;
      };

      const getBrand = function (product) {
        if (product && product.hasOwnProperty('Brand') && product.Brand.hasOwnProperty('Designation')) {
          return product.Brand.Designation;
        } else if (product && product.hasOwnProperty('brand')) {
          return product.brand;
        }
        return null;
      };

      const getReference = function (product, parentProduct) {
        if (product && product.hasOwnProperty('Reference')) {
          return product.Reference;
        } else if (product && product.hasOwnProperty('reference')) {
          return product.reference;
        } else if (parentProduct && parentProduct.hasOwnProperty('Reference')) {
          return parentProduct.Reference;
        }
        return null;
      };

      const pushToDataLayer = function (object) {
        dataLayer.push({ ecommerce: null });
        dataLayer.push(object);
      };

      /// GA4

      // Choix du paiement
      this.addPaymentInfo = async function (cart) {
        if (!(await ga4Enable())) return;
        const coupon = cart && cart.TradeActions.length > 0 ? cart.TradeActions[0].TradeActionPromoCode : null;
        const items = cart.Products.map(function (product) {
          const categories = [];
          return getItem(product, categories);
        });

        const addPaymentInfo = {
          event: 'add_payment_info',
          ecommerce: {
            currency: 'EUR',
            value: cart.ProductTotalTTC,
            coupon,
            payment_type: cart.PaymentMethod.Designation,
            items
          }
        };

        pushToDataLayer(addPaymentInfo);
      };

      // Choix de la livraison
      this.addShippingInfo = async function (cart) {
        if (!(await ga4Enable())) return;
        const coupon = cart && cart.TradeActions.length > 0 ? cart.TradeActions[0].TradeActionPromoCode : null;
        const items = cart.Products.map(function (product) { return getItem(product); });

        const addShippingInfo = {
          event: 'add_shipping_info',
          ecommerce: {
            currency: 'EUR',
            value: cart.ProductTotalTTC,
            coupon,
            items
          }
        };

        pushToDataLayer(addShippingInfo);
      };

      // Visualiser un article
      this.viewItem = async function (product, affiliation) {
        if (!(await ga4Enable())) return;
        const categories = product.parents.map(function (parent) { return parent.Designation; });

        const items = [getItem(product, categories, affiliation)];

        const viewItem = {
          event: 'view_item',
          ecommerce: {
            currency: 'EUR',
            value: product.Price.TTCDiscountedPrice,
            items
          }
        };

        pushToDataLayer(viewItem);
      };

      // Visualiser une catégorie
      this.viewItemList = async function (products, parents, affiliation) {
        if (!(await ga4Enable())) return;
        let categories = [];
        if (parents) {
          categories = parents.map(function (parent) { return parent.Designation; });
        }
        const items = products.map(function (product) {
          return getItem(product, categories, affiliation);
        });

        const viewItemList = {
          event: 'view_item_list',
          ecommerce: {
            item_list_name: categories[0],
            items
          }
        };

        pushToDataLayer(viewItemList);
      };

      // Ajout au panier
      this.addToCart = async function (tradeActions, products, currency, affiliation) {
        if (!(await ga4Enable())) return;
        const coupon = tradeActions.length > 0 && tradeActions[0].hasOwnProperty('TradeActionPromoCode') ? tradeActions[0].TradeActionPromoCode : null;
        const items = products.map(function (product) {
          let categories = [];
          if (product.parents) {
            categories = product.parents.map(function (parent) { return parent.Designation; });
          }
          return getItem(product, categories, affiliation, coupon);
        });
        // On calcule la somme des articles ajoutés au panier
        const totalTTC = products
          .map(function (product) { return (Math.round(product.Price.TTCDiscountedPrice * 100) / 100) * Math.abs(product.Quantity); })
          .reduce(function (previousValue, currentValue) {
            return (previousValue + currentValue.Price.TTCDiscountedPrice);
          });
        const addToCart = {
          event: 'add_to_cart',
          ecommerce: {
            currency,
            value: totalTTC,
            items
          }
        };

        pushToDataLayer(addToCart);
      };

      // Supprimer un article du panier
      this.removeFromCart = async function (tradeActions, products, currency, affiliation) {
        if (!(await ga4Enable())) return;
        if (products.length < 1) return;
        const coupon = tradeActions.length > 0 && tradeActions[0].hasOwnProperty('TradeActionPromoCode') ? tradeActions[0].TradeActionPromoCode : null;
        const items = products.map(function (product) { return getItem(product, null, affiliation, coupon); });
        // On calcule la somme des articles supprimés du panier
        const totalTTC = products.map(function (product) { return (Math.round(product.Price.TTCDiscountedPrice * 100) / 100) * Math.abs(product.Quantity); }).reduce(function (previousValue, currentValue) {
          return (previousValue + currentValue);
        });
        const removeFromCart = {
          event: 'remove_from_cart',
          ecommerce: {
            currency,
            value: totalTTC,
            items
          }
        };

        pushToDataLayer(removeFromCart);
      };

      // Ajouter à la liste de souhaits
      this.addToWishlist = async function (products, currency, affiliation) {
        if (!(await ga4Enable())) return;
        if (products.length < 1) return;
        const items = products.map(function (product) { return getItem(product, null, affiliation, null); });
        const totalTTC = products.map(function (product) { return (Math.round(product?.Product?.Price?.TTCDiscountedPrice * 100) / 100) * Math.abs(product?.Quantity); }).reduce(function (previousValue, currentValue) {
          return (previousValue + currentValue);
        });
        const addToWishlist = {
          event: 'add_to_wishlist',
          ecommerce: {
            currency,
            value: totalTTC,
            items
          }
        };

        pushToDataLayer(addToWishlist);
      };

      // Sélection d'un article dans une liste
      this.selectItem = async function (product, itemListId, itemListName) {
        if (!(await ga4Enable()) || product === null) return;

        const items = [getItem(product, null, null, null)];
        const selectItem = {
          event: 'select_item',
          ecommerce: {
            item_list_id: itemListId,
            item_list_name: itemListName,
            items
          }
        };

        pushToDataLayer(selectItem);
      };

      // Search
      this.search = async function (searchTerm, products, affiliation) {
        if (!(await ga4Enable())) return;
        const items = products.map(function (product) {
          return getItem(product, null, affiliation);
        });

        const viewItemList = {
          event: 'search',
          ecommerce: {
            search_term: searchTerm,
            items
          }
        };

        pushToDataLayer(viewItemList);
      };


      this.viewCart = async function (cart, currency, affiliation) {
        if (!(await ga4Enable())) return;
        const categories = [];
        const items = cart.Products.map(function (product) {
          return getItem(product, categories, affiliation);
        });

        const viewCart = {
          event: 'view_cart',
          ecommerce: {
            currency,
            value: cart.NetToPay,
            items
          }
        };

        pushToDataLayer(viewCart);
      };

      this.beginCheckout = async function (cart, currency, affiliation) {
        if (!(await ga4Enable())) return;
        const categories = [];
        const items = cart.Products.map(function (product) {
          return getItem(product, categories, affiliation);
        });

        const beginCheckout = {
          event: 'begin_checkout',
          ecommerce: {
            currency,
            value: cart.NetToPay,
            coupon: null,
            items
          }
        };

        pushToDataLayer(beginCheckout);
      };

      /* Purchase is done in back at GTMService */
      this.purchase = async function (cart, currency) {
        if (!(await ga4Enable())) return;
        const purchase = {
          event: 'purchase',
          ecommerce: {
            transaction_id: null,
            value: cart.NetToPay,
            tax: null,
            shipping: null,
            currency,
            coupon: null,
            items: null
          }
        };

        pushToDataLayer(purchase);
      };

      return {
        addPaymentInfo: this.addPaymentInfo,
        addShippingInfo: this.addShippingInfo,
        viewItem: this.viewItem,
        viewItemList: this.viewItemList,
        addToCart: this.addToCart,
        removeFromCart: this.removeFromCart,
        viewCart: this.viewCart,
        beginCheckout: this.beginCheckout,
        search: this.search,
        addToWishlist: this.addToWishlist,
        selectItem: this.selectItem
      };
    });
})();
