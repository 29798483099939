export default angular
  .module('service.price', [])

  /* @ngInject */
  .factory('PriceService', function ($filter, AppService) {
    const service = {
      get
    };
    return service;

    ////////////
    async function get(item) {
      let reduction = '';
      let priceFrom = '';
      let priceOld = '';

      if (item.Price.IsPriceFrom) {
        const { messages } = await AppService.getTranslate();
        priceFrom = `<span class="from">${messages.PriceFrom}</span>`;
      }
      if (item.Price.HasDiscount) {
        reduction = `<span class="reduction"><span class="less"><i>-</i></span><span class="amount">${$filter('discount')(item.Price.Discount)}</span></span>`;
        priceOld = `<span class="price old">${$filter('price')(item.Price)}</span>`;
      }
      const priceNew = `<span class="price new">${$filter('price')(item.Price, 'final-price')}</span>`;

      return `<div class="prices--one"><div class="prices--one-top">${priceFrom}${priceOld}</div> <div class="prices--one-bottom">${priceNew}</div></div><div class="prices--two">  ${reduction}</div>`;
    }
  });
