export default angular
  .module('service.availability', [])

  /* @ngInject */
  .factory('AvailabilityService', function ($filter, AppService, DateService) {
    const service = {
      formatMessage
    };
    return service;

    ////////////
    async function getMessage(params) {
      if (!params.active) return params.message;
      if (params.message.includes('{jours}')) {
        const days = Math.ceil((DateService.stringToDate(params.date) - new Date()) / (1000 * 60 * 60 * 24));
        if (days === 0) {
          const translate = await AppService.getTranslate();
          return translate.messages.ShippingToday;
        }
        return params.message.replace('{jours}', days);
      }

      if (params.message.includes('MMMM') || params.message.includes('mm')) {
        params.message = params.message.endsWith("'") ? params.message.slice(0, -1) : params.message;
        params.message = $filter('date')(DateService.stringToDate(params.date), `'${params.message}'`);
        params.message = $filter('truncateDate')(params.message);
      }

      return params.message;
    }

    async function formatMessage(obj, key) {
      obj[`${key}Message`] = await getMessage({
        active: obj[`${key}`],
        message: obj[`${key}DelayedMessage`] || obj[`${key}Message`],
        date: obj[`${key}Date`]
      });
      obj[`${key}MessageClass`] = obj[`${key}`] ? 'ok' : 'out';
    }
  });
