export default angular
  .module('controller.modal.store.choice', [])

  /* @ngInject */
  .controller('modalStoreChoiceCtrl', function (data, device, options, $rootScope, HttpService, AppService) {
    const ctrl = this;
    ctrl.data = data;
    ctrl.device = device;
    ctrl.options = options;

    ctrl.selectStore = async (event, idStore) => {
      event.preventDefault();
      event.stopPropagation();
      ctrl.loading = true;
      try {
        const response = await HttpService.post({
          url: ctrl.data.isAccountUpdate || ctrl.data.isAccountCreate ? '/Store/AccountSelect' : '/Store/Select',
          data: {
            IDStore: idStore,
            IDProduct: ctrl.data.idProduct,
            IsAccountCreate: ctrl.data.isAccountCreate ? true : false
          }
        });
        AppService.updateStore(response.VisitorContext);
        $rootScope.$broadcast('updateVisitorContext', response.VisitorContext);

        if (ctrl.data.targetCtrl) {
          ctrl.data.targetCtrl.setStore(response);
        }

        if (ctrl.data.action) {
          if (angular.isString(ctrl.data.action)) {
            $rootScope.$eval(ctrl.data.action)?.();
          } else {
            ctrl.data.action();
          }
        }
      } catch (error) {
        console.error(error);
      }
      ctrl.loading = false;
      !$rootScope.$$phase && $rootScope.$digest();
    };
  });
