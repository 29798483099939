import 'Modules/Shared/Autocomplete/import/autocomplete.product.tpl';

export default angular
  .module('directive.autocomplete.product', [])

  .directive('autoCompleteProduct', function () {
    return {
      restrict: 'E',
      scope: true,
      bindToController: {
        device: '<',
        action: '&',
        txtAddToList: '@',
        txtViewAndChoose: '@',
        onlyAllowAddToCart: '<?',
        excludeDilicom: '<'
      },
      controllerAs: 'searchCtrl',
      /* @ngInject */
      controller: function ($scope, $element, $injector, AppService) {
        const ctrl = this;

        ctrl.$onDestroy = () => {};

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "autocomplete.product" */ 'Modules/Shared/Autocomplete/import/autocomplete.product.js');
          $injector.loadNewModules([response.default.name, 'angucomplete-alt']);
          const service = $injector.get('ImportAutocompleteProduct');
          const { Site } = await AppService.getParams();
          service.init(ctrl, $scope, $element, Site.UseElasticCore);
        };
      }
    };
  });
