angular
  .module('account-check.directive', [])

/* Utilisation de https://regexlib.com/ et https://rgxdb.com/ */

/* @ngInject */
  .directive('accountPhone', function ($timeout, $parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        const std = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/; // France métropolitaine
        let regexPhone = std;
        const spe = {
          187: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 187 => La Réunion,
          147: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 147 => Mayotte
          197: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 197 => Saint - Pierre - et - Miquelon
          89: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 89 => Guadeloupe, Saint-Martin et Saint-Barthélemy
          97: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 97 => French Guiana(Guyane)
          144: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 144 => Martinique
          242: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 242 => Wallis-et-Futuna
          166: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 166 => Nouvelle - Calédonie
          183: /^(?:(?:\+|00|0)((262|692)|(263|693)|508|(5|6)90|(5|6)94|(5|6|7)96|681|687|689))(?:[\s.-]*\d{2}){3,4}$/, // 183 => French Polynesia
          27: /^((\+|00)32\s?|0)(\d\s?\d{3}|\d{2}\s?\d{2})(\s?\d{2}){2}$/, // 27 => Belgique
          8: /^(\d{5}([-\/]|)\d{0,9}$|((00\d{5,6}|\+\d{4,6}|\+[(]\d{1,3}[)]))\d{1,4}(|[-\/])\d{1,7}$)/, // 8 => Allemagne
          114: /^(\((00|\+)39\)|(00|\+)39)?(38[890]|34[7-90]|36[680]|33[3-90]|32[89])\d{7}$/, // 114 => Italie
          72: /^(([+]?34) ?)?(6(([0-9]{8})|([0-9]{2} [0-9]{6})|([0-9]{2} [0-9]{3} [0-9]{3}))|9(([0-9]{8})|([0-9]{2} [0-9]{6})|([1-9] [0-9]{7})|([0-9]{2} [0-9]{3} [0-9]{3})|([0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2})))$/, // 72 => Espagne
          215: /^(\+41|0041|0){1}(\(0\))?[0-9]{9}$/, // 215 => Suisse
          189: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/ // 189 => Royaume-Uni
        };
        const stdPhoneMessage = 'Le numéro de téléphone renseigné<br /> ne correspond pas à un numéro <br /> de téléphone fixe'; // France métropolitaine
        const spePhoneMessages = {
          27: 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone fixe belge', // 27 => Belgique
          8: 'Die eingegebene Telefonnummer <br/> entspricht keiner Festnetznummer', // 8 => Allemagne
          114: 'Il numero di telefono inserito <br/> non corrisponde a un numero <br /> di rete fissa', // 114 => Italie
          72: 'El número de teléfono introducido <br/> no corresponde a un número <br /> de teléfono fijo', // 72 => Espagne
          215: 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone fixe', // 215 => Suisse
          189: 'The telephone number entered <br/> does not correspond to <br /> a landline number' // 189 => Royaume-Uni
        };

        const watcher = scope.$watch(attrs.accountPhone, function (value) {
          regexPhone = (value in spe) ? spe[value] : std;
          refreshField(value);
          if (attrs.ngModel) {
            const val = modelCtrl.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        });

        scope.$on('$destroy', function () {
          watcher();
        });

        modelCtrl.$validators.phone = phoneValidator;

        function phoneValidator(modelValue) {
          if (!modelValue) {
            return true;
          }

          return regexPhone.test(modelValue);
        }

        function refreshField(country) {
          modelCtrl.$setValidity('phone', true);
          $timeout(function () {
            scope.$apply(function () {
              const message = (country in spePhoneMessages) ? spePhoneMessages[country] : stdPhoneMessage;
              attrs.$set('phone-notification', message);
              modelCtrl.$validate();
            });
          });
        }
      }
    };
  })

//Regex mobile par pays

// /^(\+33|0033|\(\+33\)|0)(6|7)( ?\d){8}$/ // France
// /^(?:\+324|04)( ?\d){8}$/, // 27 => Belgique
// /^((01[0-9])\d{9}$|(1[0-9])\d{9}$|((00\d{5,6}|\+\d{4,6}|\+[(]\d{1,3}[)]\d{3}))(|[-\/])\d{8}$)/, // 8 => Allemagne
// /^([+]39)?((38[{8,9}|0])|(34[{7-9}|0])|(36[6|8|0])|(33[{3-9}|0])|(32[{8,9}]))([\d]{7})$/, // 114 => Italie
// /^34\s?(?:6[0-9]|7[1-9])[0-9]\s?[0-9]{3}\s?[0-9]{3}$/, // 72 => Espagne
// /^(((\+41)(\s?)(\-?))|(0))(7)(\d{1})(\s?)(\-?)(\d{3})(\s?)(\-?)(\d{2})(\s?)(\-?)(\d{2})/, // 215 => Suisse
// /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/ // 189 => Royaume-Uni

  /* @ngInject */
  .directive('accountMobile', function ($timeout, $parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        const std = /^(\+|\d)[0-9]{7,16}$/; // France
        let regexMobile = std;
        const spe = {
          27: /^(\+|\d)[0-9]{7,16}$/, // 27 => Belgique
          8: /^(\+|\d)[0-9]{7,16}$/, // 8 => Allemagne
          114: /^(\+|\d)[0-9]{7,16}$/, // 114 => Italie
          72: /^(\+|\d)[0-9]{7,16}$/, // 72 => Espagne
          215: /^(\+|\d)[0-9]{7,16}$/, // 215 => Suisse
          189: /^(\+|\d)[0-9]{7,16}$/ // 189 => Royaume-Uni
        };
        const stdMobileMessage = 'Le numéro de téléphone renseigné <br /> ne correspond pas à un numéro <br /> de téléphone portable'; // France métropolitaine
        const speMobileMessage = {
          27: 'Le numéro de téléphone renseigné <br/> ne correspond pas à un numéro <br /> de téléphone portable', // 27 => Belgique
          8: 'Die eingegebene Telefonnummer <br/> entspricht keiner Mobiltelefonnummer', // 8 => Allemagne
          114: 'Il numero di telefono inserito <br/> non corrisponde a un numero di cellulare', // 114 => Italie
          72: 'El número de teléfono introducido <br/> no corresponde a un número <br /> de teléfono móvil', // 72 => Espagne
          215: 'Le numéro de téléphone renseigné<br/> ne correspond pas à un numéro<br/> de téléphone portable', // 215 => Suisse
          189: 'The telephone number entered <br/> does not correspond to a <br /> mobile telephone number' // 189 => Royaume-Uni
        };

        const watcher = scope.$watch(attrs.accountMobile, function (value) {
          regexMobile = (value in spe) ? spe[value] : std;
          refreshField(value);
          if (attrs.ngModel) {
            const val = modelCtrl.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        });

        scope.$on('$destroy', function () {
          watcher();
        });

        modelCtrl.$validators.mobile = mobileValidator;

        function mobileValidator(modelValue) {
          if (!modelValue) {
            return true;
          }

          return regexMobile.test(modelValue);
        }

        function refreshField(country) {
          modelCtrl.$setValidity('mobile', true);
          $timeout(function () {
            scope.$apply(function () {
              const message = (country in speMobileMessage) ? speMobileMessage[country] : stdMobileMessage;
              attrs.$set('mobile-notification', message);
              modelCtrl.$validate();
            });
          });
        }
      }
    };
  })

  /* @ngInject */
  .directive('accountPostcode', function ($timeout, $parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        const std = /^(.*)$/; //Par défaut pas de regex: on accepte tout
        let regexPostcode = std;
        const spe = {
          1: /^(([0-8][0-9])|(9[0-5]))[0-9]{3}$/, // France métropolitaine
          27: /^(?:(?:[1-9])(?:\d{3}))$/, // 27 => Belgique
          8: /^\d{5}$/, // 8 => Allemagne
          114: /^\d{5}$/, // 114 => Italie
          72: /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/, // 72 => Espagne
          215: /^[1-9]\d{3}$/, // 215 => Suisse
          189: /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/ // 189 => Royaume-Uni
        };
        const stdPostcodeMessage = 'Le code postal renseigné est invalide'; // France métropolitaine
        const spePostcodeMessage = {
          27: 'Le code postal renseigné est invalide', // 27 => Belgique
          8: 'Die eingegebene Postleitzahl ist ungültig', // 8 => Allemagne
          114: 'Il codice postale inserito non è valido', // 114 => Italie
          72: 'El código postal ingresado no es válido', // 72 => Espagne
          215: 'Le code postal renseigné est invalide', // 215 => Suisse
          189: 'The postal code entered is invalid' // 189 => Royaume-Uni
        };

        const watcher = scope.$watch(attrs.accountPostcode, function (value) {
          regexPostcode = (value in spe) ? spe[value] : std;
          refreshField(value);
          if (attrs.ngModel) {
            const val = modelCtrl.$viewValue;
            $parse(attrs.ngModel).assign(scope, val);
          }
        });

        scope.$on('$destroy', function () {
          watcher();
        });

        modelCtrl.$validators.postcode = postcodeValidator;

        function postcodeValidator(modelValue) {
          if (!modelValue) {
            return true;
          }

          return regexPostcode.test(modelValue);
        }

        function refreshField(country) {
          modelCtrl.$setValidity('postcode', true);
          $timeout(function () {
            scope.$apply(function () {
              const message = (country in spePostcodeMessage) ? spePostcodeMessage[country] : stdPostcodeMessage;
              attrs.$set('postcode-notification', message);
              modelCtrl.$validate();
            });
          });
        }
      }
    };
  })

  /* @ngInject */
  .directive('disallowSpaces', function () {
    return {
      restrict: 'A',

      link: function ($scope, $element) {
        $element.bind('input', function () {
          $(this).val($(this).val().replace(/ /g, ''));
        });
      }
    };
  });
