import 'import/js/directives/autocomplete/angucomplete-alt.js';
import 'Modules/Shared/Autocomplete/import/autocomplete.less';

import 'Modules/Category/import/quickview.advice.tpl';
import 'Modules/Category/import/quickview.advice.less';

export default angular
  .module('directive.quickview.advice', ['angucomplete-alt'])

  /* @ngInject */
  .directive('quickviewAdvice', function () {
    return {
      restrict: 'A',
      scope: true,
      bindToController: {},
      /* @ngInject */
      controller: function ($scope, $element, $timeout, $http) {
        const ctrl = this;

        ctrl.inputChanged = value => {
          ctrl.query = value;
        };

        ctrl.searchCallback = async selected => {
          if (typeof selected === 'undefined') return;
          if (typeof selected.Designation === 'undefined') return;

          try {
            ctrl.query = selected.Designation;

            const postData = {
              TextQuery: ctrl.query,
              From: '0',
              Size: '20',
              FiltersSelected: [
                {
                  FieldName: 'KeyWebAspect',
                  FieldValue: 'Advice' // Selectionne uniquement des articles "Conseil" dans la recherche
                }
              ]
            };

            const { data } = await ctrl.searchAPI(undefined, undefined, postData);

            ctrl.getResponse(data, ctrl.query);
            !$scope.$$phase && $scope.$digest();
          } catch (error) {
            console.error(error);
          }
        };

        ctrl.searchAPI = (userInputString, timeoutPromise, data) => searchDesignationApiCore(userInputString, timeoutPromise, data);

        ctrl.getResponse = (data, str) => {
          ctrl.query = str;

          const products = data.products;
          data.results = data.products;

          ctrl.products = products.filter(o => o.KeyWebAspect === 'Advice');

          $timeout(() => {
            $element.find('.dropdown-menu').scrollTop(0);
            $element.find('.dropdown-menu.with-img').trigger('scroll');
          }, 100);

          return data;
        };

        ctrl.goSearch = str => {
          if (str !== '') {
            ctrl.searchCallback({ Designation: str });
          }
        };

        // functions
        function searchDesignationApiCore(userInputString, timeoutPromise, search) {
          let data;

          if (search) {
            data = search;
          } else {
            data = {
              TextQuery: userInputString,
              From: '0',
              Size: '20',
              FiltersSelected: [
                {
                  FieldName: 'KeyWebAspect',
                  FieldValue: 'Advice' // Selectionne uniquement des articles "Conseil" dans la recherche
                }
              ]
            };
          }

          return $http.post('/elssearch/query', data, { timeout: timeoutPromise });
        }
      },
      controllerAs: 'quickviewAdviceCtrl'
    };
  });
