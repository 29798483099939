export default angular
  .module('controller.main', [])

  /* @ngInject */
  .controller('mainController', function ($scope, $rootScope, $timeout, $templateCache, DeviceService, HttpService, RoutingService, ModalService) {
    const ctrl = this;

    setTimeout(() => {
      loadModalAddToCart();
    });

    $scope.$on('updateVisitorContext', (event, value) => {
      $scope.visitorContext = value;
    });

    $scope.$on('pageCheckout', (event, checkout) => {
      $timeout(() => {
        ctrl.checkout = checkout;
      });
    });

    $scope.showPageLoader = false;
    $scope.$on('showPageLoader', (event, value) => {
      $scope.showPageLoader = value;
    });

    $rootScope.forceReload = () => {
      ModalService.close();
      $rootScope.$broadcast('searchClose');
      RoutingService.forceReload();
    };

    setDevice($scope);

    function setDevice(scope) {
      $scope.device = {};
      if (DeviceService.get().length > 3) {
        $timeout(() => {
          setDevice(scope);
        }, 10);
        return;
      }

      $scope.device.size = DeviceService.getSize($scope, size => {
        $scope.device.size = size;
        $scope.$emit('lazy:refresh');
      });

      $scope.device.xxs = DeviceService.onChange($scope, 'xxs', match => {
        $scope.device.xxs = match;
      });
      $scope.device.xs = DeviceService.onChange($scope, 'xs', match => {
        $scope.device.xs = match;
      });
      $scope.device.sm = DeviceService.onChange($scope, 'sm', match => {
        $scope.device.sm = match;
      });
      $scope.device.md = DeviceService.onChange($scope, 'md', match => {
        $scope.device.md = match;
      });
      $scope.device.lg = DeviceService.onChange($scope, 'lg', match => {
        $scope.device.lg = match;
      });

      $scope.device.desktop = DeviceService.onChange($scope, 'md, lg', match => {
        $scope.device.desktop = match;
      });
      $scope.device.mobile = DeviceService.onChange($scope, 'xxs, xs', match => {
        $scope.device.mobile = match;
      });

      $scope.device.isTouch = DeviceService.isTouch();

      $scope.device.ios = DeviceService.isIOS();

      $scope.device.isIe = DeviceService.isIE().isIE;
      $scope.device.ieVersion = DeviceService.isIE().isIE ? `ie${DeviceService.isIE().version}` : null;

      DeviceService.isWebp().then(result => {
        $scope.device.webp = result;
      });
    }

    async function loadModalAddToCart() {
      try {
        const data = await HttpService.get({
          url: '/Template/Modal/ModalAddToCart',
          cache: true
        });
        $templateCache.put('/Template/Modal/ModalAddToCart', data);
      } catch (error) {
        console.error(error);
      }
    }
  });
