import 'import/js/components/addtocart/component.input.quantity.detail.tpl';
import 'import/js/components/addtocart/component.input.quantity.detail.less';
/*
 * Input Quantity directive
 * Version 1.0.0
 * Octave
 * Modified from <https://github.com/Firestitch/angular-counter>.
 **/
export default angular
  .module('component.input.quantity.detail', [])

  .component('inputQuantityDetail', {
    require: {
      productCtrl: '?^^productDetail'
    },
    bindings: {
      id: '<?idProduct',
      min: '<?',
      step: '<?',
      quantity: '<?',
      width: '<?',
      size: '@?',
      btnClass: '@?',
      fieldTabindex: '@',
      keyEnter: '&?',
      action: '&?'
    },
    templateUrl: 'import/js/components/addtocart/component.input.quantity.detail.tpl',
    /* @ngInject */
    controller: function ($scope, $injector) {
      const ctrl = this;

      ctrl.$onDestroy = () => {};

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "input.quantity.detail.init" */ 'import/js/components/addtocart/component.input.quantity.detail.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitInputQuantityDetail');
        service.init(ctrl, $scope);
      };
    }
  });
