import { merge } from 'lodash-es';

export default angular
  .module('app.routing-listeners', [])

  /* @ngInject */
  .run(($rootScope, $location, $timeout, $route, $window, $anchorScroll, RoutesService, RoutingService, ModalService, HttpService, AppService, toastr) => {
    // désactivation du routing pour Google
    if (window.isBot || window.isCache) return;

    $anchorScroll.yOffset = 50;

    $location.update = ({ path, search, notReplace }) => {
      let routeToKeep = $route.current;
      let unsubscribe = $rootScope.$on('$locationChangeSuccess', () => {
        if (routeToKeep) {
          $route.current = routeToKeep;
          routeToKeep = null;
          $rootScope.$broadcast('showPageLoader', false);
        }
        unsubscribe();
        unsubscribe = null;
      });
      if (path) {
        $location.path(path);
      }
      if (search) {
        $location.search(search);
      }
      if (!notReplace) {
        $location.replace();
      }
    };

    let currentLocation;

    $rootScope.$on('$routeChangeStart', (event, next, current) => {
      if (!$rootScope.router) {
        $rootScope.router = true;
        // si le statut 404 est renvoyé par la vue
        if ($(document.body).data('status') === 404) {
          RoutingService.set({
            to: {
              name: '404'
            }
          });
          return;
        }
      }

      $rootScope.animation = null;

      // Animations dans le panier en fonction des étapes
      if (next.cart) {
        $rootScope.animation = 'page-transition';
        const _routes = RoutesService.get();
        let stepTo = 1;
        let stepFrom = 0;

        try {
          stepFrom = Object.entries(_routes).find(o => o[1].route === current.originalPath)[1].step;
          stepTo = Object.entries(_routes).find(o => o[1].route === next.originalPath)[1].step;
        } catch (ex) {}

        if (stepFrom === 0 && stepTo === 1) {
          $rootScope.animation = 'page-transition-first';
        } else if (stepTo < stepFrom) {
          $rootScope.animation = 'page-transition-back';
        }
      }

      if (!next.name) {
        const route = Object.entries(RoutesService.getMap()).find(r => r[1].route === next.originalPath);
        if (route) next.name = route[1].name;
      }

      RoutingService.set({
        to: next,
        from: current || next
      });
    });

    // eslint-disable-next-line
    $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
      if (rejection.message && rejection.message.indexOf('HTTP status: -1') !== -1) return;

      if (current.name && current.name.indexOf('Account') === 0) {
        $location.path(RoutesService.getUrlByName('Login'));
      } else if (current.name && current.name.indexOf('Cart') === 0) {
        $location.path(rejection.message === 'noCart' ? RoutesService.getUrlByName('Cart') : RoutesService.getUrlByName('CartIdentification'));
      } else {
        $rootScope.$broadcast('showPageLoader', false);
        AppService.getTranslate().then(translate => {
          toastr.warning('', translate.errors.ErrorHasOccurred, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        });
        AppService.getParams().then(params => {
          if (!params.Localization.UseLogs) return;
          let message =
            $location.path() +
            (current && current.loadedTemplateUrl ? ` | current: ${current.loadedTemplateUrl}` : '') +
            (previous && previous.loadedTemplateUrl ? ` | previous: ${previous.loadedTemplateUrl}` : '');
          if (rejection) {
            message += ` | rejection: ${rejection.message} | stack: ${rejection.stack}`;
          }
          HttpService.post({
            url: '/Log',
            data: {
              Key: '$routeChangeError',
              Message: message
            }
          });
        });
        $window.location.href = $window.location.href;
      }
    });

    // eslint-disable-next-line
    $rootScope.$on('$locationChangeStart', (event, next, current) => {
      const routing = RoutingService.get();

      // Pour les routes hors Compte et Panier, on enregistre la position du scroll
      if (!routing.to.name || (routing.to.name && routing.to.name.indexOf('Account') !== 0 && routing.to.name.indexOf('Cart') !== 0)) {
        RoutingService.scrollCache[RoutingService.getPath(current)] = $window.pageYOffset;
      }

      // si première route de l'application, on annule le traitement pour les routes hors Compte et Panier (sans variable ngRoute)
      if (!$rootScope.ngRouter && routing.to && !routing.to.ngRoute) {
        $rootScope.ngRouter = true;
        let findRoute = Object.entries($route.routes).find(o => o[1].hasOwnProperty('name') && o[1].name === routing.to.name);
        findRoute = findRoute ? findRoute[1] : {};
        $route.current = merge({}, findRoute, { params: routing.to.params });
        if ($location.hash()) $timeout($anchorScroll, 1000);

        event.preventDefault();
        return;
      }

      ModalService.close();

      // init des menus
      $('#navMenu, #navSecondary, #navAside').find('li.active').removeClass('active');
      $rootScope.$broadcast('searchClose');

      $rootScope.$broadcast('showPageLoader', true);

      if (routing.from && routing.from.name && routing.to && routing.to.name) {
        // Si on sort d'une catégorie, on supprime les query (facettes)
        if (
          (routing.from.name.indexOf('Category') === 0 || routing.from.name.indexOf('SortedCategory') === 0) &&
          ((routing.to.name.indexOf('Category') !== 0 && routing.to.name.indexOf('SortedCategory') !== 0) ||
            (routing.from.params && routing.to.params && routing.from.params.categoryId !== routing.to.params.categoryId))
        ) {
          $location.search({});
        }

        // Si on sort d'une recherche, on supprime les query (facettes)
        if (
          routing.from.name.indexOf('Search') !== -1 &&
          (routing.to.name.indexOf('Search') === -1 || (routing.from.params && routing.to.params && routing.from.params.q !== routing.to.params.q))
        ) {
          $location.search({});
        }
      }

      if (!$rootScope.ngRouter) {
        $rootScope.backToStore = '/';
        $rootScope.ngRouter = true;
      } else if (!routing.from.name || routing.from.name.indexOf('Cart') !== 0) {
        $rootScope.backToStore = current;
      }
    });

    $rootScope.$on('$locationChangeSuccess', () => {
      currentLocation = $location.url();
    });

    $rootScope.$watch(
      () => $location.url(),
      newLocation => {
        $rootScope.popstate = currentLocation === newLocation;
      }
    );

    $rootScope.$on('$viewContentLoaded', () => {
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line
      dataLayer.push({
        event: 'pageView',
        url: $location.path()
      });

      $rootScope.initRouter = true;

      $rootScope.$broadcast('showPageLoader', false);
      $rootScope.$broadcast('lazy:refresh');

      if ($location.hash()) {
        $anchorScroll();
      } else {
        setTimeout(() => {
          let getScrollPos = $rootScope.popstate;
          const routing = RoutingService.get();
          if (routing.from.name && routing.to.name) {
            if (routing.from.name.indexOf('Product') === 0 && routing.to.name.indexOf('Product') === 0 && routing.from.params.productId === routing.to.params.productId) {
              getScrollPos = true;
            } else if (routing.from.name === 'SellerPage' && routing.to.name === 'SellerPage' && routing.from.params.sellerId === routing.to.params.sellerId) {
              getScrollPos = true;
            } else if (
              routing.from.name.indexOf('Category') === 0 &&
              routing.to.name.indexOf('Category') === 0 &&
              routing.from.params.categoryId === routing.to.params.categoryId
            ) {
              getScrollPos = true;
            }
          }
          RoutingService.windowScroll(getScrollPos);
        });
      }
    });
  });
