import 'import/js/plugins/detect.js';
import 'js/dependencies.js';
import 'js/project.js';
import 'js/components.js';

/**
 * @namespace angular
 */
angular
  .module('app', ['dependencies', 'project', 'components'])

  .constant('Environment', _ENV_)

  /* @ngInject */
  .config(function ($provide) {
    // monkey-patches $templateCache to have a keys() method
    /* @ngInject */
    $provide.decorator('$templateCache', function ($delegate) {
      const keys = [];
      const put = $delegate.put;
      const remove = $delegate.remove;

      $delegate.put = function (key, value) {
        if (!keys.includes(key)) {
          keys.push(key);
        }
        return put(key, value);
      };

      $delegate.remove = function (key) {
        keys.splice(keys.indexOf(key), 1);
        return remove(key);
      };

      $delegate.getKeys = function () {
        return keys;
      };

      return $delegate;
    });
  })

  /* @ngInject */
  .config(function ($compileProvider, Environment) {
    // $qProvider.errorOnUnhandledRejections(false);
    $compileProvider.commentDirectivesEnabled(false);
    $compileProvider.cssClassDirectivesEnabled(false);
    if (Environment === 'prod') {
      $compileProvider.debugInfoEnabled(false);
    }
  })

  /* @ngInject */
  .config(function (lazyConfigProvider) {
    lazyConfigProvider.setOptions({
      offset: 200,
      errorClass: 'error',
      successClass: 'loaded'
    });
  })

  /* @ngInject */
  .run(function (AppService, bsValidationConfig) {
    AppService.getParams();

    AppService.getTranslate()
      .then(translate => {
        Object.entries(translate.forms).forEach(element => {
          translate.forms[element[0]] = element[1]
            .replace('{0}', '{{0}}')
            .replace('{1}', '{{1}}')
            .replace('{validValue}', '{{validValue}}')
            .replace('{validValue1}', '{{validValue1}}')
            .replace('{validValue2}', '{{validValue2}}')
            .replace('{matchName}', '{{matchName}}');
        });
        bsValidationConfig.setMessages(translate.forms);
        bsValidationConfig.suppressWarnings = true;
      })
      .catch(error => {
        console.error(error);
      });

    window.onunload = () => {};
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  });

angular.element(() => {
  if (!$(document).injector()) {
    angular.bootstrap(document, ['app'], {
      strictDi: true
    });
  }
});

if (module.hot) {
  module.hot.accept();
}
