import { cloneDeep } from 'lodash-es';

export default angular
  .module('service.account', [])

  /* @ngInject */
  .factory('AccountService', function ($rootScope, AppService, HttpService, RoutesService) {
    const service = {
      getLinks,
      setManagement,
      getReturnFolders,
      getShippingAddresses
    };
    return service;

    ////////////
    function getLinks() {
      const _routes = RoutesService.get();
      const links = {};

      Object.entries(_routes).forEach(element => {
        if (element[0].indexOf('Account') !== -1) {
          links[element[0]] = `/${element[1].url}`;
        }
      });

      return links;
    }

    async function setManagement(ctrl) {
      const params = await AppService.getParams();
      ctrl.Visitor = params.Visitor;
      ctrl.Account = cloneDeep(params.Account);
      ctrl.Delegation = cloneDeep(params.Delegation);

      ctrl.Account.hasServices =
        (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseModifierInfosPerso === 2) &&
        (params.Account.IsManagementGiftCards ||
          params.Account.IsManagementCredits ||
          params.Account.IsManagementDedicatedProducts ||
          params.Account.IsManagementLoyaltyPoints ||
          (params.Account.IsManagementWishlistTracking &&
            !params.Account.IsManagementMultiWishlist &&
            (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserWishList)) ||
          (params.Account.IsManagementMultiWishlist &&
            params.Account.IsManagementWishlistTracking &&
            (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserWishList)) ||
          params.Account.IsManagementSponsorShip ||
          params.Account.IsManagementVouchers);

      ctrl.Account.hasOrders =
        (params.Account.IsManagementOrderTracking && (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserCommandes)) ||
        (params.Account.IsManagementInvoicesAndCreditsTracking && (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserFactures)) ||
        params.Account.IsManagementWebCartTracking ||
        (params.Account.IsManagementReturns && (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserCommandes)) ||
        (params.Account.IsManagementComplaint && (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserCommandes)) ||
        (params.Account.IsManagementNumericProducts && (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserCommandes)) ||
        (params.Account.IsManagementQuotationTracking && (!params.Delegation?.DelegationUsed || params.Delegation?.AutoriseVisualiserDevis));
    }

    function getReturnFolders() {
      $rootScope.$broadcast('showPageLoader', true);
      return HttpService.get({
        url: '/AccountReturns/ReturnFolders',
        cache: false
      })
        .then(response => {
          $rootScope.$broadcast('showPageLoader', false);
          return response.results;
        })
        .catch(error => {
          console.error(error);
        });
    }

    function getShippingAddresses() {
      $rootScope.$broadcast('showPageLoader', true);
      return HttpService.get({
        url: '/AccountGetUserShippingAddresses',
        cache: false
      })
        .then(response => {
          $rootScope.$broadcast('showPageLoader', false);
          return response.results;
        })
        .catch(error => {
          console.error(error);
        });
    }
  });
