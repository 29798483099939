export default angular
  .module('directive.infos.store', [])

  .directive('infosStore', function () {
    return {
      restrict: 'A',
      require: {
        productCtrl: '?^^productDetail'
      },
      bindToController: {
        device: '<',
        idProduct: '<',
        availability: '<availability',
        msgExclu: '@'
      },
      scope: true,
      controllerAs: 'infosStoreCtrl',
      /* @ngInject */
      controller: function ($scope, $rootScope, AppService, ModalService, AvailabilityService, RoutingService) {
        const ctrl = this;
        let storeSelected;

        ctrl.$onInit = async () => {
          try {
            const params = await AppService.getParams();
            ctrl.Store = params.Store;
            if (ctrl.availability) {
              if (params.HasCart && params.Cart.Products.some(o => o.IDProduct === ctrl.idProduct)) {
                await setStoreData(params);
              } else {
                ctrl.Availability = {
                  Detail: {
                    DeliveryMessage: '',
                    PickUp: ctrl.availability.active,
                    PickUpMessage: ctrl.availability.message,
                    PickUpDate: ctrl.availability.date
                  }
                };
                await AvailabilityService.formatMessage(ctrl.Availability.Detail, 'PickUp');
                if (ctrl.Availability.Detail.PickUp && ctrl.msgExclu) {
                  ctrl.Availability.Detail.PickUpMessage = ctrl.msgExclu;
                }
              }
              delete ctrl.availability;
            }
          } catch (error) {
            console.error(error);
          }
          ctrl.ready = true;
          !$rootScope.$$phase && $scope.$digest();
        };

        ctrl.selectAvailableStore = data => {
          ModalService.close();
          ctrl.openStoreChoice(data, true);
        };

        ctrl.productOpenStoreChoice = (url, msgExclu) => {
          ModalService.close();
          ctrl.openStoreChoice({
            action: !url
              ? null
              : () => {
                  storeSelected = true;
                  restoreQuickView(url);
                },
            cancel: !url
              ? null
              : () => {
                  if (!storeSelected) {
                    restoreQuickView(url);
                  }
                },
            msgExclu
          });
        };

        ctrl.openStoreChoice = (data, availableFilter) => {
          ModalService.show(
            '/Template/Stores/ModalStoreChoice',
            {
              idProduct: ctrl.idProduct,
              quantity: ctrl.productCtrl?.quantity || 1,
              targetCtrl: ctrl,
              store: ctrl.Store,
              message: data.message || null,
              action: data.action || null,
              msgExclu: data.msgExclu,
              availableFilter
            },
            'lg',
            'modalStoreChoiceCtrl',
            null,
            ctrl.device,
            null,
            data.cancel || null
          );
        };

        ctrl.setStore = async data => {
          await setStoreData(data.VisitorContext);
          $rootScope.$broadcast('storeUpdate', ctrl, data);
          ModalService.close();
          !$rootScope.$$phase && $scope.$digest();
        };

        $scope.$on('storeUpdate', async (event, targetCtrl, data) => {
          if (targetCtrl !== ctrl) {
            await setStoreData(data.VisitorContext);
            !$rootScope.$$phase && $scope.$digest();
          }
        });

        async function setStoreData(data) {
          ctrl.Store = data.Store;
          if (data.HasCart && data.Cart.Products.some(o => o.IDProduct === ctrl.idProduct)) {
            ctrl.Availability = data.Cart.Products.find(o => o.IDProduct === ctrl.idProduct)?.Availability;
            await AvailabilityService.formatMessage(ctrl.Availability.Detail, 'Delivery');
            await AvailabilityService.formatMessage(ctrl.Availability.Detail, 'PickUp');

            ctrl.Availability.Detail.deliveryOutOfQuantity = !ctrl.Availability.Detail.Delivery && ctrl.Availability.Detail.CentralStock > 0;
            if (ctrl.Availability.Detail.deliveryOutOfQuantity) {
              ctrl.Availability.Detail.DeliveryMessage = ctrl.availability.messageDeliveryOutOfQuantity;
              ctrl.Availability.Detail.DeliveryMessageClass = 'exclamation out';
            }

            ctrl.Availability.Detail.pickUpOutOfQuantity =
              !ctrl.Availability.Detail.PickUp && (ctrl.Availability.Detail.StoreStock > 0 || ctrl.Availability.Detail.CentralStock > 0);
            if (ctrl.Availability.Detail.pickUpOutOfQuantity) {
              ctrl.Availability.Detail.PickUpMessage = ctrl.availability.messagePickUpOutOfQuantity;
              ctrl.Availability.Detail.PickUpMessageClass = 'exclamation out';
            }

            if (ctrl.Availability.Detail.PickUp && ctrl.msgExclu) {
              ctrl.Availability.Detail.PickUpMessage = ctrl.msgExclu;
            }

            if (ctrl.productCtrl) {
              ctrl.productCtrl.hideRazorAvailability = true;
            }
          } else {
            RoutingService.forceReload();
          }
        }

        function restoreQuickView(url) {
          ModalService.show(
            '/Template/Modal/ModalQuickView',
            {
              tempUrl: `${url}?view=ProductQuickView&nc=${new Date().getTime()}`,
              url
            },
            'lg',
            null,
            null,
            ctrl.device
          );
        }
      }
    };
  });
