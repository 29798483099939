import { pullAllBy } from 'lodash-es';

export default angular
  .module('service.webcart', [])

  /* @ngInject */
  .factory('WebCartService', function ($http, $rootScope, $timeout, HttpService, toastr, AppService, NetwaveService, GA4Service) {
    const cartRequests = [];

    const service = {
      addToCart,
      deleteLine,
      itemQuantityInCart,
      checkAvailability
    };
    return service;

    function addToCart(products, checkCumulativeProduct = false) {
      const productsToCart = {
        Products: [],
        CheckCumulativeProduct: checkCumulativeProduct
      };

      products.forEach(product => {
        productsToCart.Products.push({
          IDLine: product.idLine || 0,
          IDProduct: product.idProduct || 0,
          Reference: product.Reference || '',
          Quantity: product.quantity,
          Comment: product.Comment || '',
          Elements: product.Elements || null,
          Customization: product.Customization || null,
          CustomizablePrice: product.CustomizablePrice
            ? {
                Price: product.CustomizablePrice.current
              }
            : null
        });
      });
      const tmpId = new Date().getTime();
      cartRequests.push({ id: tmpId });
      return $http({
        method: 'POST',
        url: '/Product/AddToCart',
        headers: {
          'Content-type': 'application/json',
          'X-Requested-With': 'jsonHttpRequest'
        },
        data: productsToCart
      })
        .then(response => {
          pullAllBy(cartRequests, [{ id: tmpId }], 'id');

          // Netwave
          if (response.data.status === 'OK' && (!products || products !== null) && products.length > 0) {
            const product = response.data.results.Cart.Products.find(p => p.IDProduct === products[0].idProduct);
            if (product) {
              products[0].KitUnitTTCPrice = product.Product.IsKit ? Math.round(product.TTCAmount / product.Quantity * 100) / 100 : 0;
              products[0].IsKit = product.Product.IsKit;
            }
            if (products[0].quantity > 0) {
              NetwaveService.addArticleToCart(products[0], products[0].quantity);
            } else {
              NetwaveService.removeArticleFromCart(products[0], -(products[0].quantity));
            }
          }

          // GA4 Add To Cart / Remove From Cart
          try {
            const currency = response.data.results.Currency && response.data.results.Currency.ISOCode ? response.data.results.Currency.ISOCode : null;
            if (response.data?.removedProducts.length > 0) {
              GA4Service.removeFromCart(response.data.results.Cart.TradeActions, response.data.removedProducts, currency, response.data.results.Localization.TradeName);
            } else {
              GA4Service.addToCart(response.data.results.Cart.TradeActions, response.data.addedProducts, currency, response.data.results.Localization.TradeName);
            }
          } catch (error) {
            console.error(error);
          }

          return response.data;
        })
        .catch(error => {
          throw error;
        });
    }

    function deleteLine(idLine, product, designation, img, imgSrc, message, fromCart) {
      $rootScope.$broadcast('showPageLoader', true);
      HttpService.get({
        url: `/RemoveCartLine/${idLine}`,
        cache: false
      })
        .then(async response => {
          if (response.status === 'OK') {
            AppService.updateParams(response.results);
            toastr.success(designation, message, {
              allowHtml: true,
              extraData: {
                template: 'import/js/libs/angular-toastr/toast_addtocart.tpl',
                data: {
                  img,
                  imgSrc
                }
              }
            });
            // Netwave
            const qty = product.quantity > 0 ? product.quantity : -(product.quantity);
            await NetwaveService.removeArticleFromCart(product, qty);
            try {
              const currency = response.results.Currency && response.results.Currency.ISOCode ? response.results.Currency.ISOCode : null;
              GA4Service.removeFromCart(response.results.Cart.TradeActions, [response.removedProducts], currency, response.results.Localization.TradeName);
            } catch (error) {
              console.error(error);
            }
            deleteLineCallback(fromCart, response);
          } else {
            AppService.getTranslate()
              .then(messages => {
                toastr.warning(messages.errors.TryLater, messages.errors.ErrorHasOccurred, {
                  allowHtml: true,
                  extraData: {
                    template: 'import/js/libs/angular-toastr/toast_message.tpl'
                  }
                });
              })
              .catch(error => {
                console.error(error);
              });
          }
          $rootScope.$broadcast('showPageLoader', false);
        })
        .catch(error => {
          console.error(error);
        });
    }

    function deleteLineCallback(fromCart, response) {
      if (fromCart && !response.results.Cart.Products.length) {
        $rootScope.$broadcast('showPageLoader', true);
        $timeout(() => {
          $rootScope.$broadcast('showPageLoader', true);
        }, 450);
        window.location = $rootScope.backToStore;
      }
    }

    function itemQuantityInCart(id, hash = '') {
      return AppService.getParams()
        .then(data => {
          if (!data.HasCart) return 0;

          let qty = 0;
          data.Cart.Products.forEach(product => {
            if (product.IDProduct === id) {
              if (hash !== '') {
                if (product.Comment === hash) {
                  qty = product.Quantity;
                  return false;
                }
              } else {
                qty = product.Quantity;
                return false;
              }
            }
          });
          return qty;
        })
        .catch(error => {
          console.error(error);
        });
    }

    function checkAvailability() {
      return HttpService.post({
        url: '/Cart/checkAvailability'
      })
        .then(response => response)
        .catch(error => {
          console.error(error);
        });
    }
  });
