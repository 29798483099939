import 'import/js/components/addtocart/component.addtocart.quantity.tpl';
import 'import/js/components/addtocart/component.addtocart.quantity.less';

export default angular
  .module('component.addtocart.quantity', [])

  .component('buttonAddToCartQuantity', {
    require: {
      productCtrl: '?^^productDetail',
      quotationCtrl: '?^^accountAdvancedQuotations'
    },
    bindings: {
      isProductButton: '<?',
      isCartButton: '<?',
      isQuotationButton: '<?',
      idQuotation: '<?',
      isModalButton: '<?',
      modalItem: '=',
      action: '&?',
      size: '@?',
      btnClass: '@',
      idLine: '<?',
      commentLine: '@?',
      id: '<idProduct',
      isPrimary: '<',
      addToCartActive: '<?',
      allowAddToCart: '<',
      productQuantity: '<',
      min: '<',
      msgMin: '@?',
      step: '<',
      price: '<productPrice',
      designation: '@productDesignation',
      img: '@productImg',
      distrimageSrc: '@productDistrimageSrc',
      url: '@productUrl',
      titleAdd: '@?',
      titleAlert: '@?',
      titleDetail: '@?',
      titleDeleteOk: '@?',
      titleModifyOk: '@?',
      tooltip: '@',
      modalCtrl: '<',
      device: '<'
    },
    templateUrl: 'import/js/components/addtocart/component.addtocart.quantity.tpl',
    /* @ngInject */
    controller: function ($scope, $injector) {
      const ctrl = this;

      ctrl.$onDestroy = () => {};

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "addtocart.quantity.init" */ 'import/js/components/addtocart/component.addtocart.quantity.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('InitAddtocartQuantity');
        service.init(ctrl, $scope);
      };
    }
  });
