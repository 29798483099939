export default angular.module('directive.quotation-payment', []).directive('quotationPayment', function () {
  return {
    restrict: 'A',
    bindToController: {
      device: '<',
      step: '<',
      list: '<?',
      link: '@?'
    },
    controllerAs: 'cartCtrl',
    /* @ngInject */
    controller: function ($scope, $injector, $element) {
      const ctrl = this;

      ctrl.$onInit = async () => {
        const response = await import(/* webpackChunkName: "payment" */ 'Modules/Payment/import/quotation-payment.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('QuotationPayment');
        service.initModule(ctrl, $scope, $element);
      };
    }
  };
});
