export default angular
  .module('controller.newsletter', [])

  /* @ngInject */
  .controller('newsletterController', function ($rootScope, $element, $timeout, HttpService, AppService, LogService, ModalService, toastr) {
    const ctrl = this;

    ctrl.formData = {};

    ctrl.submit = async () => {
      ctrl.loading = true;

      try {
        const { status } = await HttpService.post({
          url: '/Newsletter/Subscribe',
          data: ctrl.formData
        });
        const { messages, errors } = await AppService.getTranslate();
        if (status === 'OK') {
          ctrl.submitted = true;
          toastr.success('', messages.NewsletterOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
          $timeout(() => {
            ctrl.form.$hideValidation();
            $element.find('.form-control').blur();
            ctrl.formData.Email = '';
            ctrl.form.$resetValidation();
          }, 2000);
        } else if (status === 'ERROR_CONNECTION_EXPECTED') {
          LogService.redirect = {
            url: null
          };
          ModalService.show(
            '/Template/Authentication/ModalAuthentication',
            {
              newsletterConnect: true,
              mailRecognized: ctrl.formData.Email
            },
            null,
            'loginModalCtrl'
          );
        } else if (status === 'REGISTRATION_ALREADY_EXISTS') {
          toastr.success('', messages.NewsletterDejaOK, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        } else {
          toastr.warning(errors.TryLater, errors.ErrorHasOccurred, {
            allowHtml: true,
            extraData: {
              template: 'import/js/libs/angular-toastr/toast_message.tpl'
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
      ctrl.loading = false;
      !$rootScope.$$phase && $rootScope.$digest();
    };
  });
