export default angular
  .module('service.alma', [])

  /* @ngInject */
  .factory('AlmaService', function (HttpService, RoutesService, $window) {
    let paymentResponse;
    let apiInfo;
    let paymentForm;
    let idFolder;
    let isFromCart;
    let paymentCtrl;

    const service = {
      init,
      submit,
      getApiInfo
    };

    return service;

    /**
     * Fonction d'initialisation du service Alma
     * @param {any} ctrl Le controller auquel on intègre Alma
     */

    async function init(ctrl, id, isCart) {
      paymentCtrl = ctrl;
      idFolder = id;
      isFromCart = isCart;
      paymentResponse = await getPaymentData();
      apiInfo = (await getApiInfo());
      const eligibilities = await getEligibility();
      paymentCtrl.almaEligibilities = eligibilities.filter(e => Boolean(e.eligible));

    }


    /**
     * Envoi de la requête de paiement
     * Effectue une pré-requête à nos serveur pour mettre à jour les dossiers et vérifier le montant, puis lance la requête Alma
     */
    async function submit() {
      const data = paymentResponse.results.paymentInfo;
      const response = await prePaymentRequest(data.payment.purchase_amount);
      if (response.status === 'KO') {
        // le montant du paiement et du dossier ne sont pas identiques
        redirectToError();
        return;
      }
      paymentForm.pay();
    }

    function redirectToError() {
      let url = '';
      if (isFromCart) {
        url = RoutesService.getUrlByName('CartPaymentError');
      } else {
        url = RoutesService.getUrlByName('QuotationPaymentError').replace(/\{.*\}/, idFolder.toString());
      }
      $window.location.href = url;
    }

    /**
     * Récupère les informations de disponibilité des différentes échéances
     */
    async function getEligibility() {
      let url = RoutesService.getUrlByName('AlmaCheckEligibility');
      url += `?IDFolder=${idFolder}`;
      let eligibilityResponse;
      await HttpService.get({
        url,
        cache: false
      })
        .then(response => {
          if (response.status === 'OK') {
            eligibilityResponse = response.results.Eligibilities;
          }
        })
        .catch(error => {
          console.error(error);
        });
      return eligibilityResponse;
    }

    /**
     * Récupère les informations de paiement
     */
    async function getPaymentData() {
      let url = RoutesService.getUrlByName('AlmaGetPaymentInfos');
      url += `?IDFolder=${idFolder}`;
      return HttpService.get({
        url,
        cache: false
      });
    }

    /**
     * Récupère les infos api
     */
    async function getApiInfo() {
      if (!apiInfo) {
        const url = RoutesService.getUrlByName('AlmaGetApiInfos');
        await HttpService.get({
          url,
          cache: true
        })
          .then(response => { apiInfo = response.results.apiInfo; })
          .catch(function (error) {
            console.error(error);
          });
      }
      return apiInfo;
    }

    /**
     * Fonction d'initialisation du service Alma
     * @param {number} IDFolder
     * @param {decimal} montant à payer
     */
    async function prePaymentRequest(amount) {
      let url = RoutesService.getUrlByName('AlmaPrePaymentRequest');
      url += `?IDFolder=${idFolder}&Amount=${amount}&IsCart=${isFromCart}`;
      return HttpService.get({
        url,
        cache: false
      });
    }
  });
