export default angular
  .module('service.country', [])

  /* @ngInject */
  .factory('CountryService', function () {

    // List of languages and their default country
    const countries = {
      fr: 'fr',
      en: 'gb',
      es: 'es'
    };

    const service = {
      getCountry
    };
    return service;

    ////////////
    function getCountry(lang) {
      if (lang && countries[lang]) {
        return countries[lang];
      }
      return countries.fr;
    }
  });
