import 'import/js/plugins/jquery.equalheights.js';

export default angular
  .module('module.products.grid', [])

  /* @ngInject */
  .directive('productsGrid', function (WindowEventsService, ProductsGridService) {
    return {
      restrict: 'A',
      link: (scope, element) => {
        if (!element.children('.row').length) return;

        const tmpId = element.attr('id') || `pg_${ProductsGridService.getId()}`;
        element.attr('id', tmpId);

        setTimeout(() => {
          WindowEventsService.listen(true, 'resize', resize, 500);
        });

        scope.$on('$destroy', () => {
          WindowEventsService.listen(false, 'resize', resize);
        });

        resize();

        // Functions
        function resize() {
          setTimeout(() => {
            ProductsGridService.resizeGrid(tmpId, scope.device.size, false);
          });
        }
      }
    };
  })

  .service('ProductsGridService', function () {
    let tmpId = 0;
    this.getId = () => tmpId++;
    this.resizeGrid = (elemId, size, lazy, sizes) => {
      const $elem = $(`#${elemId}`);
      const $divs = $elem.children('.row').children('.grid:visible');

      if ($divs.length < 2) return;

      if (!lazy) {
        $divs.children().addClass('init').find('.content').children().height('');
      }

      let nb;
      if (!sizes) {
        const col = $divs.eq(0).attr('class').split(`col-${size}-`)[1];
        if (!col) {
          return;
        }
        nb = 12 / col.split(' ')[0];
      } else {
        const width = document.body.clientWidth;
        [...sizes].reverse().forEach(o => {
          if (width < o.breakpoint) {
            nb = o.nb;
          }
        });
      }
      if (nb === 1) return;

      let i, j, targ;
      const len = $divs.find('.product-item').length;
      for (i = 0; i < len; i += nb) {
        targ = $divs
          .slice(i, i + nb)
          .children('.init')
          .removeClass('init')
          .find('.content');

        const n = targ.eq(0).children().length;
        for (j = 0; j < n; j++) {
          targ.find(`.${$(targ.eq(0).children().eq(j)).attr('class').split(' ')[0]}`).equalHeights();
        }
      }
    };
  });
