export default angular
  .module('directive.stores', [])

  .directive('stores', function () {
    return {
      restrict: 'E',
      require: {
        cartCtrl: '?^^cartOnePageCheckout'
      },
      bindToController: {
        device: '<',
        modalCtrl: '<?',
        daysModel: '@days',
        strOneTime: '@oneTime',
        strTwoTimes: '@twoTimes',
        strClosed: '@closed',
        localization: '@?'
      },
      controllerAs: 'storesCtrl',
      /* @ngInject */
      controller: function ($scope, $injector) {
        const ctrl = this;

        ctrl.$onInit = async () => {
          const response = await import(/* webpackChunkName: "stores" */ 'Modules/Stores/import/stores.js');
          $injector.loadNewModules([response.default.name]);
          const service = $injector.get('ImportStores');
          service.init(ctrl, $scope);
        };
      }
    };
  });
