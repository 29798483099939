/* From http://cesardeazevedo.github.io/ng-input/ */
export default angular
  .module('directives.form', [])

  /* @ngInject */
  .directive('rbInput', function () {
    const template = [
      '<span class="input">',
      '<label class="input-label" for="{0}">',
      '<span class="input-label-content"></span>',
      '</label>',
      '<span class="form-control-feedback error glyphicon glyphicon-exclamation-sign"></span>',
      '<span class="form-control-feedback success glyphicon glyphicon-ok"></span>',
      '</span>'
    ].join('');

    return {
      restrict: 'A',
      /* require: 'ngModel',*/
      priority: 200,
      scope: {
        theme: '@',
        label: '@?',
        icon: '@',
        color: '@',
        ngRequired: '='
        /* ngRequired:  '@valRequired',
        ngPattern:   '@',
        ngMinlength: '=valMinlengthMin',
        ngMaxlength: '=',
        ngChange:    '&ngChange',
        ngTrim:      '=',
        ngModel:     '=',
        trigger : '@focus'*/
      },
      link: (scope, element, attrs) => {
        const _id = element.attr('id');
        const _template = template.replace('{0}', _id || '');
        const $wrapper = angular.element(_template);
        element.after($wrapper);
        $wrapper.prepend(element);

        scope.input = element;

        // Default options
        attrs.theme = attrs.theme || 'default';
        attrs.type = attrs.type || 'text';
        attrs.color = attrs.color || '1';
        const optRequired = attrs.optRequired === 'true' ? ' opt-required' : '';

        const inputClass = 'input-filled';

        element.addClass(`form-control input-field input-field-${attrs.theme}`).parent().addClass(`input-${attrs.theme}${optRequired}`);

        const $label = element.parent().find('label');
        $label.attr('for', attrs.id).addClass(`input-label-${attrs.theme}`).find('.input-label-content').addClass(`input-label-content-${attrs.theme}`);

        if (angular.isDefined(attrs.label)) {
          if (attrs.required) {
            scope.label = `${scope.label} <sup>*</sup>`;
          }
          $label.find('.input-label-content').html(scope.label);
        }

        if (angular.isDefined(attrs.placeholder)) {
          if (attrs.required) {
            if (angular.isUndefined(attrs.requiredStarDisabled)) {
              element.attr('placeholder', `${attrs.placeholder} *`);
            } else {
              element.attr('placeholder', attrs.placeholder);
            }
          }
        }

        if (scope.icon && scope.icon !== '') {
          element.addClass('with-icon');
          $label.prepend(`<i class="glyphicon ${scope.icon}"></i>`);
        }

        onInputChange();
        scope.input.on('focus', onInputFocus);
        scope.input.on('blur', onInputBlur);

        // To detect browser autofill
        scope.input.on('change', onInputChange);

        if (angular.isDefined(attrs.ngRequired)) {
          scope.$watch(
            () => scope.ngRequired,
            value => {
              if (angular.isDefined(attrs.label)) {
                if (attrs.name === 'EMailDestinataire') {
                  scope.label = !value ? attrs.label : `${attrs.label} <sup>**</sup>`;
                } else {
                  scope.label = !value ? attrs.label : `${attrs.label} <sup>*</sup>`;
                }
                $label.find('.input-label-content').html(scope.label);
              }
            }
          );
        }

        scope.$watch(
          () => scope.input.val(),
          () => {
            onInputChange();
          }
        );

        function onInputChange() {
          if (scope.input.val().trim() !== '') addClass();
        }

        function onInputFocus() {
          addClass();
        }

        function onInputBlur() {
          if (scope.input.val().trim() === '') removeClass();
        }

        function addClass() {
          $wrapper.addClass(inputClass);
        }

        function removeClass() {
          $wrapper.removeClass(inputClass);
        }
      }
    };
  })

  /* @ngInject */
  .directive('ngModelInit', function ($parse) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: (scope, element, attrs) => {
        let value = attrs.ngModelInit || attrs.value || element.text();
        value = String(value).toLowerCase() === 'false' ? false : value;
        value = String(value).toLowerCase() === 'true' ? true : value;
        $parse(attrs.ngModel).assign(scope, value);
      }
    };
  })

  .directive('input', noZoomDirective)
  .directive('textarea', noZoomDirective)
  .directive('select', noZoomDirective);

function noZoomDirective() {
  const viewport = window.document.getElementById('viewport');
  const content = viewport.content;
  const maxScale = ',maximum-scale=';

  return {
    link: (scope, element) => {
      element.on('focus blur', event => {
        viewport.content = event.type === 'blur' ? content : content + maxScale + 1;
      });
    }
  };
}
