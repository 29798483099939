export default angular
  .module('finder', [])

  /* @ngInject */
  .component('finder', {
    scope: true,
    bindings: {
      btnFilters: '<',
      rangeFilters: '<',
      priceStep: '<',
      maxFilters: '<',
      nbPerPage: '<',
      rangeText: '@',
      device: '<'
    },
    template: '',
    /* @ngInject */
    controller: function ($scope, $element, $injector, $compile, HttpService, nicescrollConfig) {
      const ctrl = this;

      ctrl.$onDestroy = () => {};

      ctrl.$onInit = loadTemplate;

      ctrl.nicescrollOptions = {
        ...nicescrollConfig,
        zindex: 1000000
      };

      function loadTemplate() {
        HttpService.get({
          url: `/Template/Finder/Finder${ctrl.device.mobile ? '?mobile=1' : ''}`,
          cache: true
        })
          .then(data => {
            $element.html(data);
            $compile($element.contents())($scope);
            loadCode();
          })
          .catch(error => {
            console.error(error);
          });
      }

      async function loadCode() {
        const response = await import(/* webpackChunkName: "finder" */ 'Modules/Finder/import/finder.init.js');
        $injector.loadNewModules([response.default.name]);
        const service = $injector.get('ImportFinder');
        service.init(ctrl, $scope, $element);
      }
    }
  });
