/*
 * Bootstrap Validation
 * Version 1.0.7
 * Modified from <https://github.com/sagrawal14/bootstrap-angular-validation>.
 **/

(function() {
	'use strict';

	angular
		.module('bootstrap.angular.validation', [])

		.directive('form',
			['$parse', 'bsValidationService',
			function ($parse, bsValidationService) {
	    return {
	      restrict: 'E',
	      require: 'form',
	      priority: 1000,     // Setting a higher priority so that this directive compiles first.
	      compile: function ($formElement, $formAttributes) {
	        if (bsValidationService.isValidationDisabled($formElement)) {
	          return;
	        }

	        // Disable HTML5 validation display
	        $formElement.attr('novalidate', 'novalidate');
	        bsValidationService.addDirective($formElement);

	        var ngSubmit = $formAttributes.ngSubmit;
	        /*
	         * Removing ngSubmit attribute if any since ngSubmit by default doesn't respects the validation errors
	         * on the input fields.
	         */
	        delete $formAttributes.ngSubmit;

	        var preLinkFunction = function ($scope, formElement, $attr, formController) {
	          // Expose a method to manually show the validation state
	          formController.$showValidation = function () {
	            formController.$setSubmitted();
	            // Tell form elements to show validation state
	            $scope.$broadcast('onBsValidationStateChange', {showValidationState: true});
	          };

	          formController.$hideValidation = function () {
	            formController.$setPristine();
	            // Tell form elements to hide validation state
	            $scope.$broadcast('onBsValidationStateChange', {showValidationState: false});
	          };
	          formController.$resetValidation = function () {
	            $scope.$broadcast('onBsValidationReset');
	          };


	          var markPristineAfterSubmit = formElement[0].attributes.hasOwnProperty('bs-pristine-on-submit');

	          formElement.on('submit', function (event) {
	            // If any of the form element has not passed the validation
	            if (formController.$invalid) {
	              // Then focus the first invalid element
	              formElement[0].querySelector('.ng-invalid[ng-model]').focus();
	              return false;
	            }

	            // Parse the handler of ng-submit & execute it
	            var submitHandler = $parse(ngSubmit);
	            $scope.$apply(function () {
	              submitHandler($scope, {$event: event});

	              formController.$commitViewValue();
	              formController.$setSubmitted();

	              if (markPristineAfterSubmit) {
	                formController.$hideValidation();
	              }
	            });

	            if (markPristineAfterSubmit) {
	              /**
	               * Prevent other submit event listener registered via Angular so that we can mark the form with
	               * the pristine state. Otherwise, that Angular's listener is getting called at the last and is again
	               * setting form to the submitted.
	               *
	               * https://api.jquery.com/event.stopimmediatepropagation/
	               */
	              event.stopImmediatePropagation();
	              event.preventDefault();
	            }

	            return true;
	          });
	        };

	        return {
	          pre: preLinkFunction
	        };
	      }
	    };
	  }])

	  .directive('bsValidation',
	  	['bsValidationService', 'bsValidationConfig',
	  	function (bsValidationService, bsValidationConfig) {
	    return {
	      restrict: 'A',
	      require: ['?ngModel', '?^^form'],
	      link: function ($scope, $element, $attr, controllers) {
	        if (bsValidationService.isValidationDisabled($element)) {
	          return;
	        }

	        // Initialize controllers
	        var ngModelController = controllers[0];
	        var ngFormController = controllers[1];

	        if (!ngModelController) {
	          if (!bsValidationConfig.suppressWarnings) {
	            console.warn('ng-model directive is required for the bs-validation directive to work.');
	          }
	          return;
	        }

	        var $formGroupElement = bsValidationService.getFormGroupElement($element);
	        if (!$formGroupElement) {
	          if (!bsValidationConfig.suppressWarnings) {
	            console.warn('No parent form group element found for input element');
	          }
	          return;
	        }

	        var displayValidationState = false;
	        var shouldValidateOnBlur = bsValidationService.shouldValidateOnBlur($element);
	        var shouldValidateOnDisplay = bsValidationService.shouldValidateOnDisplay($element);
	        var shouldValidateOnSubmit = bsValidationService.shouldValidateOnSubmit($element);

	        var displayErrorAs = bsValidationService.displayErrorPreference($element, $attr);
	        var validationMessageService = bsValidationService.getValidationMessageService(displayErrorAs);

	        // Register generic custom validators if added to element
	        angular.forEach(bsValidationService.getValidators(), function (validator) {
	          var key = validator.name;
	          var attrValue = $element.attr(key);
	          if ($attr[key] || (angular.isDefined(attrValue) && attrValue !== false)) {
	            bsValidationService.addValidator($scope, $element, $attr, ngModelController, validator);
	          }
	        });

	        function addErrorClass() {
	          bsValidationService.addErrorClass($formGroupElement);
	        }

	        function removeSuccessClass() {
	          bsValidationService.removeSuccessClass($formGroupElement);
	        }

	        function displayError() {
	          addErrorClass();
	          validationMessageService.showErrorMessage($element, $attr, ngModelController, $formGroupElement);

	          if($element.hasClass('show-and-hide')){
	            setTimeout(function () {
	              $element.blur();
	              hideSuccess();
	              hideError();
	              ngModelController.$setUntouched();
	              ngModelController.$setPristine();
	              watchBlur();
	            },3000);
	          }
	        }

	        function hideError() {
	          validationMessageService.hideErrorMessage($element, $formGroupElement);
	        }

	        function addSuccessClass() {
	          if (ngModelController.$$attr.required) {
	            bsValidationService.addSuccessClass($formGroupElement);
	          }
	          return hideError();
	        }

	        function displaySuccess() {
	          addSuccessClass();
	        }

	        function hideSuccess() {
	          removeSuccessClass();
	        }

	        function displayOrHideValidationState() {
	          //if (!ngModelController.$$attr.required && !ngModelController.$$attr.pattern) return;

	          bsValidationService.toggleErrorKeyClasses($formGroupElement, ngModelController.$error);

	          if (!displayValidationState) {
	            hideSuccess();
	            return hideError();
	          }

	          if (ngModelController.$valid) {
	            return displaySuccess();
	          }
	          if (ngModelController.$invalid) {
	            return displayError();
	          }
	        }

	        function showValidation() {
	          displayValidationState = true;
	          displayOrHideValidationState();
	        }

	        function hideValidation() {
	          displayValidationState = false;
	          displayOrHideValidationState();
	        }

	        function watchBlur() {
	          var dewatcher = $scope.$watch(function () {
	            return ngModelController.$touched;
	          }, function (lostFocus) {
	            if (lostFocus) {
	              displayValidationState = true;
	              displayOrHideValidationState();
	              dewatcher();
	            }
	          });
	        }

	        if (shouldValidateOnBlur) {
	          watchBlur();
	        }

	        if (shouldValidateOnSubmit && ngFormController) {
	          // register watchers for submission touch and valid
	          $scope.$watch(function () {
	            return ngFormController.$submitted;
	          }, function (submitted) {
	            displayValidationState = submitted;
	            displayOrHideValidationState();
	          });
	        }

	        if (shouldValidateOnDisplay) {
	          showValidation();
	        }

	        // TODO Find alternative for this watch
	        /*$scope.$watch(function () {
	          return ngModelController.$error;
	        }, displayOrHideValidationState, true);*/

	        $scope.$watch(function () {
	          return ngModelController.$valid;
	        }, displayOrHideValidationState, true);

	        $scope.$watch(function () {
	          return ngModelController.$$attr.required;
	        }, function () {
	          hideSuccess();
	          hideError();
	          displayOrHideValidationState();
	        }, true);

	        $scope.$on('onBsValidationStateChange', function (event, data) {
	          displayValidationState = data.showValidationState;
	          displayOrHideValidationState();
	        });

	        $scope.$on('onBsValidationReset', function (event) {
	          $element.blur();
	          hideSuccess();
	          hideError();
	          ngModelController.$setUntouched();
	          ngModelController.$setPristine();
	          watchBlur();
	        });


	        $scope.$on('$destroy', function () {
	          validationMessageService.destroyMessage($element);
	        });

	        ngModelController.$showValidation = showValidation;
	        ngModelController.$hideValidation = hideValidation;
	      }
	    };
	  }])

		.provider('bsValidationConfig', function () {

		  // Can be a string or list of any combination of "blur", "submit" & "display"
		  var validateFieldsOn = 'blur';
		  // Display the validation error message below the `input` field with class "help-block"
		  var displayErrorsAs = 'simple';

		  // Can be a string or list of any combination of filters; will be applied in order
		  // For example: 'lowercase' or ['lowercase', 'reverse'] (So long as the filter(s) exists)
		  var messageFilters = [];

		  function shouldValidateOn(event) {
		    if (angular.isString(validateFieldsOn)) {
		      return validateFieldsOn === event;
		    }

		    return validateFieldsOn.indexOf(event) !== -1;
		  }

		  var _this = this;
		  this.global = {};
		  this.global.addSuccessClass = true;
		  this.global.errorClass = 'has-error';
		  this.global.successClass = 'has-success';
		  this.global.errorMessagePrefix = '';
		  this.global.tooltipPlacement = 'bottom-left';
		  this.global.tooltipAppendToBody = false;
		  this.global.suppressWarnings = false;

		  this.global.messages = {};

		  this.setMessages = function(data) {
		    _this.global.messages = data;
		  };

		  this.global.setValidateFieldsOn = function (event) {
		    if (!event) {
		      throw 'Please provide an string or list of events to validate fields on';
		    }

		    if (!angular.isString(event) && !angular.isArray(event)) {
		      throw 'Event should either be a string or a list';
		    }

		    validateFieldsOn = event;
		  };

		  this.global.setDisplayErrorsAs = function (type) {
		    if (!type) {
		      throw 'Please provide the way validation error should be displayed. In-built options are "simple" & "tooltip".';
		    }

		    displayErrorsAs = type;
		  };

		  this.global.useMessageFilters = function (filters) {
		    if (!filters) {
		      throw 'Please provide a string or list of filters to apply to messages';
		    }

		    if (!angular.isString(filters) && !angular.isArray(filters)) {
		      throw 'Filters should either be a string or a list';
		    }

		    messageFilters = filters;

		    if (!angular.isArray(messageFilters)) {
		      messageFilters = [messageFilters];
		    }
		  };

		  this.$get = [function () {
		    return {
		      messages: function (key) {
		        return _this.global.messages[key];
		      },
		      errorClass: _this.global.errorClass,
		      successClass: _this.global.successClass,
		      suppressWarnings: _this.global.suppressWarnings,
		      tooltipAppendToBody: _this.global.tooltipAppendToBody,

		      getDisplayErrorsAs: function () {
		        return displayErrorsAs;
		      },

		      getErrorMessagePrefix: function () {
		        return _this.global.errorMessagePrefix || '';
		      },

		      getMessageFilters: function () {
		        return messageFilters;
		      },

		      getTooltipPlacement: function () {
		        return _this.global.tooltipPlacement;
		      },

		      shouldAddSuccessClass: function () {
		        return _this.global.addSuccessClass;
		      },

		      shouldValidateOn: shouldValidateOn,

		      setMessages: function (data) {
		        return _this.setMessages(data);
		      }
		    };
		  }];
		})

		.factory('bsValidationService',
			['$interpolate', '$injector', '$filter', 'bsValidationConfig',
			function ($interpolate, $injector, $filter, bsValidationConfig) {
	    var displayErrorAsAttrName = 'bsDisplayErrorAs';
	    var customFormGroup = '[bs-form-group]';
	    var formGroupClass = '.form-group';

	    var _genericValidators = [{
	      name: 'digits',
	      validateFn: function (value) {
	        return (/^\d+$/).test(value);
	      }
	    }, {
	      name: 'equalto',
	      validateFn: function (value, $scope, attr) {
	        return value + '' === $scope.$eval(attr.equalto) + '';
	      }
	    }, {
	      name: 'number',
	      validateFn: function (value) {
	        return (/^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/).test(value);
	      }
	    }, {
	      name: 'min',
	      validateFn: function (value, $scope, attr) {
	        return parseFloat(value) >= parseFloat(attr.min);
	      }
	    }, {
	      name: 'max',
	      validateFn: function (value, $scope, attr) {
	        return parseFloat(value) <= parseFloat(attr.max);
	      }
	    }, {
	      name: 'range',
	      validateFn: function (value, $scope, attr) {
	        return parseFloat(value) >= parseFloat(attr.range.split('|')[0]) && parseFloat(value) <= parseFloat(attr.range.split('|')[1]);
	      }
	    }, {
	      name: 'length',
	      validateFn: function (value, $scope, attr) {
	        return value.length === parseInt(attr.length);
	      }
	    }, {
	      name: 'strictemail',
	      validateFn: function (value) {
	        return new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/).test(value);
	      }
	    }];

	    function getTrigger($element, triggerEvent) {
	      var attributeName = 'bs-trigger';
	      if ($element.attr(attributeName)) {
	        return $element.attr(attributeName) === triggerEvent;
	      }

	      var parentForm = $element.parents('form');
	      if (parentForm && parentForm.attr(attributeName)) {
	        return parentForm.attr(attributeName) === triggerEvent;
	      }

	      // Use the global config
	      return bsValidationConfig.shouldValidateOn(triggerEvent);
	    }

	    function removeClassByPrefix(element, prefix) {
	      var regx = new RegExp('\\b' + prefix + '.*\\b', 'g');
	      element[0].className = element[0].className.replace(regx, '').replace(/\s\s+/g, ' ');
	      return element;
	    }

	    if (!String.prototype.camelCaseToDash) {
			  String.prototype.camelCaseToDash = function () {
			    return this.replace(/([A-Z])/g, function ($1) {
			      return '-' + $1.toLowerCase();
			    });
			  };
			}

	    var selectors = [];
	    var elements = ['input', 'select', 'textarea'];

	    angular.forEach(elements, function (element) {
	      selectors.push(element + '[ng-model]');
	    });

	    var selector = selectors.join(',');
	    var meta = ['matchName'];

	    return {
	      getValidators: function () {
	        return _genericValidators;
	      },

	      getMetaInformation: function ($element) {
	        var metaInformation = {};

	        angular.forEach(meta, function (key) {
	          metaInformation[key] = $element.attr(key) || $element.attr(key.camelCaseToDash());
	        });

	        return metaInformation;
	      },

	      addDirective: function ($element) {
	        var validateableElements = $element.find(selector);
	        validateableElements.attr('bs-validation', '');
	        return validateableElements;
	      },

	      addErrorClass: function ($formGroupElement) {
	        this.removeSuccessClass($formGroupElement);
	        $formGroupElement.addClass(bsValidationConfig.errorClass);
	      },

	      addSuccessClass: function ($formGroupElement) {
	        this.removeErrorClass($formGroupElement);

	        if (bsValidationConfig.shouldAddSuccessClass()) {
	          $formGroupElement.addClass(bsValidationConfig.successClass);
	        }
	      },

	      addValidator: function ($scope, $element, $attr, ngModelController, validator) {
	        ngModelController.$validators[validator.name] = function (modelValue, viewValue) {
	          var value = modelValue || viewValue;
	          if (ngModelController.$isEmpty(value)) {
	            return true;
	          }

	          // See https://github.com/sagrawal14/angular-extras/blob/v0.1.3/src/extras/array.js#L91 for "find" function
	          return validator.validateFn(value, $scope, $attr);
	        };
	      },

	      /**
	       * Add a custom validator to the list of generic validators.
	       * @param genericValidationObject for example, to a add a generic validator to accept either "foo" or "bar":
	       * {
	       *     name: 'foobar',
	       *     validateFn: function(value, $scope, attr) {
	       *         return value === 'foo' || value === 'bar';
	       *     }
	       * }
	       */
	      addGenericValidator: function (genericValidationObject) {
	        _genericValidators.push(genericValidationObject);
	      },

	      displayErrorPreference: function ($element, $attr) {
	        var attrName = displayErrorAsAttrName;
	        if ($attr[attrName]) {
	          return $attr[attrName];
	        } else {
	          var $parentForm = $element.parents('form');

	          // .attr() method not accepting camelCase version of the attribute name. Converting it to dashed-case
	          attrName = attrName.camelCaseToDash();

	          if ($parentForm && $parentForm.attr(attrName)) {
	            return $parentForm.attr(attrName);
	          }
	        }

	        // Use the global preference
	        return bsValidationConfig.getDisplayErrorsAs();
	      },

	      getErrorMessage: function ($element, $attr, ngModelController) {
	        var firstErrorKey = Object.keys(ngModelController.$error)[0];
	        return bsValidationConfig.getErrorMessagePrefix() + this.resolveMessage($element, $attr, firstErrorKey);
	      },

	      getFormGroupElement: function ($element) {
	        // First search for an attribute with 'bs-form-group'
	        var formGroupElement = $element.parents(customFormGroup);

	        if (!formGroupElement || formGroupElement.length === 0) {
	          // Then search for parent element with class form-group
	          formGroupElement = $element.parents(formGroupClass);

	          if (!formGroupElement || formGroupElement.length === 0) {
	            return null;
	          }
	        }

	        return formGroupElement;
	      },

	      getValidationMessageService: function (displayType) {
	        var validationMessageService;

	        try {
	          validationMessageService = $injector.get(displayType + 'MessageService');
	        } catch (e) {
	          throw 'No message service found for type [' + displayType + '].';
	        }

	        if (displayType === 'tooltip' && !$injector.has('$uibPosition')) {
	          throw '$uibPosition service required from the ui-bootstrap module in order to use the tooltip message.';
	        }

	        return validationMessageService;
	      },

	      isValidationDisabled: function ($element) {
	        var attribute = 'bs-no-validation';
	        if ($element[0].attributes.hasOwnProperty(attribute)) {
	          return true;
	        }

	        var $parentForm = $element.parents('form');
	        return $parentForm[0] && $parentForm[0].attributes.hasOwnProperty(attribute);
	      },

	      removeErrorClass: function ($formGroupElement) {
	        $formGroupElement.removeClass(bsValidationConfig.errorClass);
	      },

	      removeSuccessClass: function ($formGroupElement) {
	        $formGroupElement.removeClass(bsValidationConfig.successClass);
	      },

	      resolveMessage: function ($element, $attr, key) {
	        var metaInformation = this.getMetaInformation($element);
	        var messageFilters = $element.attr(key + '-notification-filter') || bsValidationConfig.getMessageFilters();
	        var message = $element.attr(key + '-notification') || bsValidationConfig.messages(key);

	        if (!message) {
	          console.warn('No message found for the key [' + key + ']. Consider adding a global message or element' +
	            ' specific message using attribute [' + key + '-notification="My custom message"]');

	          message = 'Please fix this field';
	        }

	        if (angular.isDefined(messageFilters)) {
	          if (!angular.isArray(messageFilters)) {
	            messageFilters = [messageFilters];
	          }

	          for (var i = 0; i < messageFilters.length; i++) {
	            message = $filter(messageFilters[i])(message);
	          }
	        }

	        var attrKey = $attr[key];
	        var matchers;
	        if (attrKey && angular.isFunction(attrKey.indexOf)) {
	          matchers = angular.extend({}, {
	          validValue: attrKey,
	          validValue1: attrKey.indexOf('|') !== -1 ? attrKey.split('|')[0] : '',
	          validValue2: attrKey.indexOf('|') !== -1 ? attrKey.split('|')[1] : ''
	        }, metaInformation);
	        } else {
	          matchers = angular.extend({}, {
	            validValue: attrKey
	          }, metaInformation);
	        }

	        return $interpolate(message)(matchers);
	      },

	      shouldValidateOnBlur: function ($element) {
	        return getTrigger($element, 'blur');
	      },

	      shouldValidateOnDisplay: function ($element) {
	        return getTrigger($element, 'display');
	      },

	      shouldValidateOnSubmit: function ($element) {
	        return getTrigger($element, 'submit');
	      },

	      /**
	       * Add or remove various classes on form-group element. For example, if an input has two errors "required" & "min"
	       * then whenever the validation fails, form-group element will have classes like "bs-has-error-required" or
	       * "bs-has-error-min".
	       * @param $formGroupElement jQLite/jQuery form-group element
	       * @param errors Errors object as returned by ngModelController.$error
	       */
	      toggleErrorKeyClasses: function ($formGroupElement, errors) {
	        removeClassByPrefix($formGroupElement, 'bs-has-error-');

	        angular.forEach(errors, function (value, key) {
	          $formGroupElement.addClass('bs-has-error-' + key);
	        });
	      }
	    };
	  }])

		.factory('simpleMessageService',
			['bsValidationService',
			function (bsValidationService) {
		  var errorElementClass = '.bs-invalid-msg';

		  function getErrorContainer($element, $formGroupElement) {
		    var $errorContainer;

		    // If input element has "id" attribute
		    if ($element.attr('id')) {
		      // Then first try to find the error container with the same id prefixed with "bs-error-"
		      $errorContainer = $formGroupElement.find('#bs-error-' + $element.attr('id'));
		      if ($errorContainer && $errorContainer.length) {
		        return $errorContainer;
		      }
		    }

		    $errorContainer = $formGroupElement.find(errorElementClass);
		    if ($errorContainer && $errorContainer.length) {
		      return $errorContainer;
		    }

		    var insertAfter;

		    // Check if the container have any Bootstrap input group then append the error after it
		    var groupElement = $formGroupElement.find('.input-group');
		    if (groupElement.length > 0) {
		      insertAfter = groupElement;
		    } else {
		      insertAfter = $element;
		    }

		    var errorContainerHTML = '<span class="help-block ' + errorElementClass.substring(1) + '" ';
		    if ($element.attr('id')) {
		      errorContainerHTML += 'id="bs-error-' + $element.attr('id') + '"';
		    }
		    errorContainerHTML += '></span>';
		    $errorContainer = angular.element(errorContainerHTML);

		    insertAfter.after($errorContainer);
		    return $errorContainer;
		  }

		  return {
		    destroyMessage: function () {
		      // Need not to do anything. Error elements will be automcatically removed on DOM unload
		    },

		    hideErrorMessage: function ($element, $formGroupElement) {
		      bsValidationService.removeErrorClass($formGroupElement);

		      var $errorContainer = getErrorContainer($element, $formGroupElement);
		      $errorContainer.html('').addClass('ng-hide');
		    },

		    showErrorMessage: function ($element, $attr, ngModelController, $formGroupElement) {
		      var message = bsValidationService.getErrorMessage($element, $attr, ngModelController);

		      var $errorContainer = getErrorContainer($element, $formGroupElement);
		      $errorContainer.html(message).removeClass('ng-hide');
		    }
		  };
		}])

		.factory('tooltipMessageService',
			['$injector', '$interpolate', '$templateCache', 'bsValidationConfig', 'bsValidationService',
			function ($injector, $interpolate, $templateCache, bsValidationConfig, bsValidationService) {
	    function getElementID($element) {
	      var id = $element.attr('id');
	      if (id) {
	        return id;
	      }

	      id = 'bs-' + (Math.floor(Math.random() * 10000)) + '-' + Math.floor(Math.random() * 10000);
	      $element.attr('id', id);
	      return id;
	    }

	    function getErrorTooltip($element) {
	      var tooltipID = 'bs-error-' + getElementID($element);
	      var tooltipElement = document.getElementById(tooltipID);

	      if (tooltipElement) {
	        return angular.element(tooltipElement);
	      }

	      var data = {errorClass: bsValidationConfig.errorClass, tooltipID: tooltipID};
	      var html = $templateCache.get('bav/template/tooltip.html');
	      html = $interpolate(html)(data);

	      if (bsValidationConfig.tooltipAppendToBody) {
	        angular.element(document.body).append(html);
	      } else {
	        $element.parent().append(html);
	      }

	      return angular.element(document.getElementById(tooltipID));
	    }

	    function getTooltipPlacement($element) {
	      var attributeName = 'bs-tooltip-placement';
	      if ($element.attr(attributeName)) {
	        return $element.attr(attributeName);
	      }

	      var parentForm = $element.parents('form');
	      if (parentForm && parentForm.attr(attributeName)) {
	        return parentForm.attr(attributeName);
	      }

	      // Use the global config
	      return bsValidationConfig.getTooltipPlacement();
	    }

	    return {
	      destroyMessage: function ($element) {
	        if (bsValidationConfig.tooltipAppendToBody) {
	          // If tooltip messages were appended to body then remove them
	          getErrorTooltip($element).remove();
	        }
	      },

	      hideErrorMessage: function ($element, $formGroupElement) {
	        bsValidationService.removeErrorClass($formGroupElement);
	        getErrorTooltip($element).removeClass('in');
	      },

	      showErrorMessage: function ($element, $attr, ngModelController) {
	        var message = bsValidationService.getErrorMessage($element, $attr, ngModelController);
	        var $errorTooltip = getErrorTooltip($element);
	        var placement = getTooltipPlacement($element);
	        var appendToBody = bsValidationConfig.tooltipAppendToBody;

	        $errorTooltip.find('.tooltip-inner').html(message);

	        var $position = $injector.get('$uibPosition');
	        var ttPosition = $position.positionElements($element, $errorTooltip, placement, appendToBody);
	        $errorTooltip.css({ top: ttPosition.top + 'px', left: ttPosition.left + 'px' });
	        $errorTooltip.addClass('in');

	        if (ttPosition.placement && !$errorTooltip.hasClass(ttPosition.placement.split('-')[0])) {
	          $errorTooltip.addClass(ttPosition.placement.split('-')[0]);
	        }

	        $position.positionArrow($errorTooltip, ttPosition.placement);
	      }
	    };
	  }])

	  .run(
	  	['$templateCache',
	  	function ($templateCache) {
		  $templateCache.put('bav/template/tooltip.html', '<div class="tooltip {{errorClass}}" id="{{tooltipID}}"' + ' role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>');
		}]);
}());