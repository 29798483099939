import 'Modules/Product/import/component.product-bottom.tpl';

export default angular
  /**
   * @class specifics.Product
   * @memberOf specifics
   * @desc This module provides necessary components and directives to handle
   * proper rendering of productBottom page
   */
  .module('component.product-bottom', [])
  /**
   * @class specifics.Product.productBottom
   * @memberOf specifics.Product
   * @desc This component allows to register template parts for description, advices, suggestions etc...
   *  to be displayed in different ways depending on user's current device
   * @param {Object} device represents user's device (web7's mainCtrl.device)
   * @property {Array<string>} slots List of allowed transclusion slots
   * @example
   * <product-bottom device="device">
   *    <bottom-slot> This content is dynamically rendered depending on the device </bottom-slot>
   * </product-bottom>
   */
  .component('productBottom', {
    templateUrl: 'Modules/Product/import/component.product-bottom.tpl',
    transclude: {
      description: '?bottomDescription',
      advices: '?bottomAdvices',
      reviews: '?bottomReviews',
      suggestions: '?bottomSuggestions',
      seen: '?bottomAlreadySeen',
      recipes: '?bottomRecipes'
    },
    bindings: {
      device: '<'
    },
    controllerAs: 'bottomCtrl',
    controller: function ($transclude) {
      const ctrl = this;

      ctrl.slots = ['description', 'suggestions', 'advices', 'recipes', 'reviews', 'seen'];
      /**
       * @typedef Section
       * @property {string} slot Transclusion slot
       * @property {string} label Lexicon provided label
       */
      ctrl.sections = [];
      ctrl.sectionSet = {};

      /**
       * Register a provided section
       * @param {Section} section
       */
      ctrl.addSection = section => {
        if (section.slot in ctrl.sectionSet) {
          return;
        }

        ctrl.sectionSet[section.slot] = true;
        ctrl.sections.push(section);
      };

      /**
       * Wether content has been provided for the given slot (eg <bottom-description> registers a 'description' slot)
       * @param {string} slot name of a transclusion scope
       */
      ctrl.exists = slot => $transclude.isSlotFilled(slot);

      /**
       * Returns the lexicon label for the corresponding section
       * @param {string} slot name of a transclusion scope
       */
      ctrl.getLabel = slot => `<span class='h3'>${ctrl.sections.find(s => s.slot === slot)?.label}</span>`;
    }
  });
