export default angular
  .module('service.http', [])

  /* @ngInject */
  .factory('HttpService', function ($http) {
    return {
      get: object =>
        $http({
          method: 'GET',
          url: object.url,
          cache: object.cache,
          headers: {
            'Content-type': 'application/json',
            'X-Requested-With': 'jsonHttpRequest'
          }
        })
          .then(response => response.data)
          .catch(error => {
            throw error;
          }),
      post: object =>
        $http({
          method: 'POST',
          data: object.data,
          url: object.url,
          headers: {
            'Content-type': 'application/json',
            'X-Requested-With': 'jsonHttpRequest'
          }
        })
          .then(response => response.data)
          .catch(error => {
            throw error;
          })
    };
  })

  /* @ngInject */
  .factory('ApiService', function ($http, AppService, toastr) {
    const service = {
      $get: _get,
      $post: _post,
      $put: _put,
      $delete: _delete,
      successNotity,
      errorNotity
    };
    return service;

    ////////////

    function _get(obj) {
      return _request(angular.extend({}, { method: 'GET' }, obj));
    }

    function _post(obj) {
      return _request(angular.extend({}, { method: 'POST' }, obj));
    }

    function _put(obj) {
      return _request(angular.extend({}, { method: 'PUT' }, obj));
    }

    function _delete(obj) {
      return _request(angular.extend({}, { method: 'DELETE' }, obj));
    }

    function _request(obj) {
      return $http(angular.extend({}, { headers: { 'Content-type': 'application/json', 'X-Requested-With': 'apiRequest' } }, obj))
        .then(response => response)
        .catch(error => {
          throw error;
        });
    }

    function successNotity(message) {
      toastr.success('', message, {
        extraData: {
          template: 'import/js/libs/angular-toastr/toast_message.tpl'
        }
      });
    }
    async function errorNotity() {
      const translate = await AppService.getTranslate();
      toastr.warning(translate.errors.TryLater, translate.errors.ErrorHasOccurred, {
        allowHtml: true,
        extraData: {
          template: 'import/js/libs/angular-toastr/toast_message.tpl'
        }
      });
    }
  });
